import { ProfileComponent } from './pages/profile/profile.component';
import { VenueComponent } from './pages/venue/venue.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { UsersComponent } from './pages/users/users.component';
import { EventsDetailComponent } from './pages/events-detail/events-detail.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { EventCreateComponent } from './pages/event-create/event-create.component';
import { UsersDetailComponent } from './pages/users-detail/users-detail.component';
import { SpacesComponent } from './pages/spaces/spaces.component';
import { SpacesDetailComponent } from './pages/spaces-detail/spaces-detail.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { RequestsDetailComponent } from './pages/requests-detail/requests-detail.component';
import { CustomersDetailComponent } from './pages/customers-detail/customers-detail.component';
import { VendorsDetailComponent } from './pages/vendors-detail/vendors-detail.component';
import { EventsComponent } from './pages/events/events.component';
import { LoginGuard } from './login.guard';
import { SearchvenueComponent } from './pages/searchvenue/searchvenue.component';
import { EnterOtpComponent } from './pages/enter-otp/enter-otp.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { EventsDetailTimelinePrintComponent } from './pages/events-detail-timeline-print/events-detail-timeline-print.component';

const routes: Routes = [
  { path: '', redirectTo: '/events', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [LoginGuard] },
  { path: 'events', component: EventsComponent, canActivate: [LoginGuard] },
  { path: 'vendors', component: VendorsComponent, canActivate: [LoginGuard] },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [LoginGuard],
  },
  { path: 'users', component: UsersComponent, canActivate: [LoginGuard] },
  { path: 'spaces', component: SpacesComponent, canActivate: [LoginGuard] },
  { path: 'requests', component: RequestsComponent, canActivate: [LoginGuard] },
  {
    path: 'space/new',
    component: SpacesDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'space/:id',
    component: SpacesDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'event/new',
    component: EventsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'event/:id',
    component: EventsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'event/:id/timeline',
    component: EventsDetailTimelinePrintComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'user/new',
    component: UsersDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'user/:id',
    component: UsersDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'request-detail/new',
    component: RequestsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'request-detail/:id',
    component: RequestsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'customers-detail/new',
    component: CustomersDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'customers-detail/:id',
    component: CustomersDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'vendors-detail/new',
    component: VendorsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'vendors-detail/:id',
    component: VendorsDetailComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'user-details',
    component: UsersDetailComponent,
    canActivate: [LoginGuard],
  },
  { path: 'sign-in', component: SignInComponent },
  {
    path: 'searchvenue',
    component: SearchvenueComponent,
    canActivate: [LoginGuard],
  },
  { path: 'venue/:id', component: VenueComponent, canActivate: [LoginGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [LoginGuard] },
  { path: 'reset', component: EnterOtpComponent },
  { path: 'create-password', component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
