<mat-toolbar>
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Search this space">
    <div class="input-group-append">
      <button class="btn btn-secondary" type="button">
        <i class="material-icons">search</i>
      </button>
    </div>
  </div>
  <span class="text-right">
    <button mat-button>
      <a routerLink='/customers-detail/new'><i class="material-icons">add_circle</i></a>
    </button>
  </span>
</mat-toolbar>

<table  matSort (matSortChange)="sortData($event)">
	<thead>
		<tr class="vq-header">
			<th mat-sort-header="name">Space Name</th>
			<th mat-sort-header="calories">Phone</th>
			<th mat-sort-header="fat">Email</th>
		</tr>
	</thead>
	<tbody>
		<tr class="vq-rows" *ngFor="let customer of customers">
			<td><a [routerLink]="['/space/'+space.id]"> {{ customer.name }}</a></td>
			<td>{{ customer.phone }}</td>
			<td>{{ customer.email}}</td>
		</tr>
	</tbody>
</table>