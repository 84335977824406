<app-spinner [show]="pdfGenerateFlag"></app-spinner>
<div class="example-container">
  <form class="text-center border border-light p-5" #eventForm="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Party Name</mat-label>
            <input
              type="text"
              matInput
              placeholder="Party Name"
              name="partyName"
              [(ngModel)]="eventDetail.partyName"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Coordinator</mat-label>
            <mat-select
              [(ngModel)]="eventDetail.coordinator.id"
              name="eventCoordinator"
              (selectionChange)="OptionChanged()"
              #coordinator="ngModel"
              required
            >
              <mat-option
                *ngFor="let coordinator of coordinators"
                [value]="coordinator?.id"
                >{{ coordinator.username }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="coordinator.invalid"
              >Please Select coordinator</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Event Space</mat-label>
            <mat-select
              [(ngModel)]="eventDetail.eventSpace.id"
              name="eventSpace"
              #eventSpace="ngModel"
              required
            >
              <mat-option
                *ngFor="let space of venueSpaces"
                [value]="space.id"
                >{{ space.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="eventSpace.invalid"
              >Please Select Event Space</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Event Type</mat-label>
            <mat-select
              [(ngModel)]="eventDetail.eventType"
              name="eventType"
              #type="ngModel"
              required
            >
              <mat-option value="Corporate">Corporate</mat-option>
              <mat-option value="Event">Event</mat-option>
              <mat-option value="Family">Family</mat-option>
              <mat-option value="Nonprofit">Nonprofit</mat-option>
              <mat-option value="Party">Party</mat-option>
              <mat-option value="Social">Social</mat-option>
              <mat-option value="Styled Shoot">Styled Shoot</mat-option>
              <mat-option value="Wedding">Wedding</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="type.invalid">Please Select Event Type</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Guest Count</mat-label>
            <input
              type="number"
              matInput
              placeholder="Guest Count"
              [(ngModel)]="eventDetail.guestCount"
              name="guestCount"
            />
          </mat-form-field>
          <!-- <mat-form-field class="example-full-width">         
          </mat-form-field> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              type="text"
              matInput
              placeholder="Description"
              name="description"
              [(ngModel)]="eventDetail.description"
            ></textarea>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Event Date: </mat-label>
            <input
              type="text"
              matInput
              ngModel
              name="onDate"
              bsDatepicker
              #dp="bsDatepicker"
              [minDate]="minDateValue"
              [(bsValue)]="onDate"
              value="{{ eventDetail.onDate | date: 'MM-dd-yyyy' }}"
              [(ngModel)]="onDate"
              class="form-control"
              placeholder="Choose a date"
            />
            <!-- <input type="text" matInput placeholder="Event Date" name="onDate" [(ngModel)]="eventDetail.onDate"
              required> -->
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Start Time</mat-label>
            <input
              matInput
              aria-label="default time"
              [(ngModel)]="eventDetail.startTime"
              name="startTime"
              placeholder="Start Time"
              (timeChanged)="changeTime($event)"
              [value]="eventDetail.startTime"
            />
            <!--    <ngx-material-timepicker [enableKeyboardInput]="true" #defaultValue></ngx-material-timepicker>
           <input type="time" matInput id="input" [(ngModel)]="eventDetail.startTime" class="form-control"
              [value]="eventDetail.startTime"  mdbInput /> -->
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>End Time</mat-label>
            <!-- <input type="text" matInput placeholder="End Time" name="endTime" [(ngModel)]="eventDetail.endTime"
              > -->
            <input
              matInput
              aria-label="default time"
              [(ngModel)]="eventDetail.endTime"
              name="endTime"
              placeholder="End Time"
              (timeChanged)="changeTime($event)"
              [value]="eventDetail.endTime"
            />
            <mat-timepicker
              #defaultValue1
              [enableKeyboardInput]="true"
            ></mat-timepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <button
        mat-button
        class="btn mat-raised-button bg-secondary mr-2"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        type="submit"
        *ngIf="isCreate"
        class="btn mat-raised-button bg-primary"
        (click)="onSubmit(eventForm)"
      >
        Create
      </button>
      <button
        type="submit"
        *ngIf="!isCreate"
        class="btn mat-raised-button bg-primary"
        (click)="Update(eventForm)"
      >
        Update
      </button>
    </div>
  </form>
</div>

<div class="p-3">
  <mat-tab-group
    animationDuration="0ms"
    *ngIf="!isCreate"
    mat-align-tabs="start"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/clock-solid.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>TimeLine</span>
      </ng-template>
      <div class="text-right actionss">
        <a (click)="createTimeLine()"
          ><i class="large material-icons">add_circle</i>
          <p class="start">Add TimeLine Item</p>
        </a>

        <p
          (click)="showTimelinePrintView()"
          style="cursor: pointer"
          class="start flexi"
        >
          Print
          <span style="font-size: 30px" class="material-icons marginl-2">
            picture_as_pdf
          </span>
        </p>
      </div>
      <table
        id="contentToConvert1"
        class="mat-elevation-z8"
        matSort
        (matSortChange)="sortData($event)"
      >
        <thead>
          <tr class="vq-header">
            <th mat-sort-header="onTime">When</th>
            <th>What</th>
            <th>Where</th>
            <th>Description</th>
            <th
              *ngIf="!pdfGenerateFlag"
              class="header-filter"
              style="text-transform: capitalize"
            >
              {{ accessType | titlecase }}
              <mat-select
                class="filter-select"
                (selectionChange)="onAccessChange($event)"
                name="access"
                required
              >
                <mat-option value="all">All Access</mat-option>
                <mat-option value="client">Client Only</mat-option>
                <mat-option value="vendor">Vendor Only</mat-option>
                <mat-option value="client/vendor"
                  >Client/Vendor Only</mat-option
                >
                <mat-option value="venue">Venue Only</mat-option>
              </mat-select>
            </th>
            <th *ngIf="!pdfGenerateFlag">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="vq-rows" *ngFor="let timeline of filteredTimeLineItems">
            <td>{{ timeline.onTime | date: 'hh:mm' }}</td>
            <td (click)="editTimeLine(timeline)">
              <a [routerLink]="[]"> {{ timeline.item }}</a>
            </td>

            <td>{{ timeline.where }}</td>
            <td><span [innerHTML]="timeline.description"></span></td>
            <td *ngIf="!pdfGenerateFlag">{{ timeline.access }}</td>
            <td *ngIf="!pdfGenerateFlag">
              <span (click)="deleteTimeLine(timeline.id)" class="delete">
                Delete<i class="material-icons"> delete </i>
              </span>
              <span (click)="editTimeLine(timeline)" class="update ml-1">
                Update <i class="material-icons"> history </i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/stickynote.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>Event Notes</span>
      </ng-template>
      <div class="text-right actionss">
        <a (click)="showAddNote()">
          <i class="large material-icons">add_circle</i>
          <p class="start">Add Note</p>
        </a>
      </div>
      <table
        class="mat-elevation-z8"
        matSort
        (matSortChange)="sortDataNote($event)"
      >
        <thead>
          <tr class="vq-header">
            <th>Date</th>
            <th>Note</th>

            <th class="header-filter" style="text-transform: capitalize">
              {{ accessTypeNote | titlecase }}
              <mat-select
                class="filter-select"
                (selectionChange)="onAccessChange($event)"
                name="accessNote"
                required
              >
                <mat-option value="all">All Access</mat-option>
                <mat-option value="client">Client Only</mat-option>
                <mat-option value="vendor">Vendor Only</mat-option>
                <mat-option value="client/vendor"
                  >Client/Vendor Only</mat-option
                >
                <mat-option value="venue">Venue Only</mat-option>
              </mat-select>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="vq-rows" *ngFor="let note of eventNotes">
            <td>{{ note.date | date: 'MM-dd-yyyy' }}</td>

            <td (click)="editNote(note)">
              <span [innerHTML]="note.note"></span>
            </td>
            <td>{{ note.access }}</td>
            <td>
              <span (click)="deleteEventNote(note.id)" class="delete">
                Delete<i class="material-icons"> delete </i>
              </span>
              <span (click)="editNote(note)" class="update ml-1">
                Update <i class="material-icons"> history </i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
    <!-- FLOOR PLANS -->
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/chair-solid.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>Floor Plan</span>
      </ng-template>
      <!-- <div class="square" *ngIf="eventDetail.floorPlan">
          <img  (click)="openPreview()" [src]="eventDetail.floorPlan" alt="">
          <a class="remove-image" [routerLink]="null" (click)="removeImage()" style="display: inline;">&#215;</a>
        </div>  -->
      <div class="floor-plan-container">
        <div
          class="square"
          *ngFor="let plan of eventDetail.floorPlans; let i = index"
        >
          <img (click)="openPreview(plan.url)" [src]="plan.url" alt="" />
          <button
            class="remove-image"
            [routerLink]="null"
            (click)="removeImage(plan.id, eventDetail.id, i)"
            style="display: inline"
          >
            &#215;
          </button>
        </div>
      </div>
      <div *ngIf="!eventDetail.floorPlan" style="text-align: center">
        No Floor Plan
      </div>
      <button class="mb bg-primary" (click)="fileInput.click()">
        <i class="large material-icons">library_add</i>
        <span>Select</span>
        <input
          #fileInput
          accept=".png, .jpg, .jpeg"
          type="file"
          (change)="onFileInput($event)"
          style="display: none"
        />
      </button>
    </mat-tab>

    <!-- VENDORS LIST -->
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/utensils-solid.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>Vendors</span>
      </ng-template>

      <div class="text-right actionss">
        <a (click)="showAddVendor()">
          <i class="large material-icons">add_circle</i>
          <p class="start">Add Vendor</p>
        </a>

        <p
          (click)="sendConfirmation()"
          style="cursor: pointer"
          class="start flexi"
        >
          Send Confirmation
          <span style="font-size: 30px" class="material-icons">
            confirmation_number</span
          >
        </p>
      </div>

      <table
        id="text-center-vendors"
        class="mat-elevation-z8"
        matSort
        (matSortChange)="sortDataVendors($event)"
      >
        <thead>
          <tr class="vq-header">
            <!-- <th mat-sort-header="name">Vendor Name</th> -->
            <th mat-sort-header="name">Vendor Name</th>
            <th mat-sort-header="type">Type of Vendor</th>
            <th mat-sort-header="email">Email</th>
            <th mat-sort-header="status">Status</th>
            <th>Action</th>
            <!-- <th mat-sort-header="carbs">Price Range</th> -->
          </tr>
        </thead>
        <tbody>
          <tr class="vq-rows" *ngFor="let vendor of vendors">
            <!-- <td>{{ vendor?.name }}</td> -->
            <td>{{ vendor?.vendor?.name }}</td>
            <td>{{ vendor?.vendorType?.name }}</td>
            <td>{{ vendor?.email }}</td>
            <td>{{ vendor?.status }}</td>
            <td>
              <span (click)="deleteEventVendor(vendor.id)" class="delete">
                Delete<i class="material-icons"> delete </i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/rings.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>Clients</span>
      </ng-template>

      <div class="text-right">
        <a (click)="openDialogCustomer()"
          ><i class="large material-icons">add_circle</i>
          <p class="start">Add Client</p>
        </a>
      </div>
      <span class="text-right">
        <button mat-button>
          <!-- <a [routerLink]=null></a> -->
        </button>
      </span>
      <table
        id="text-center-vendors"
        class="mat-elevation-z8"
        matSort
        (matSortChange)="sortDataClients($event)"
      >
        <thead>
          <tr class="vq-header">
            <th mat-sort-header="name">Name</th>
            <th mat-sort-header="email">Email</th>
            <th>Action</th>
            <!-- <th mat-sort-header="calories">Mobile</th>
              <th mat-sort-header="calories">Role</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let customer of customers" class="vq-rows">
            <td>{{ customer?.user?.name }}</td>
            <td>
              <a href="mailto:{{ customer?.user?.email }}">
                {{ customer?.user?.email }}</a
              >
            </td>
            <td>
              <span (click)="deleteUser(customer.id)" class="delete">
                Delete<i class="material-icons"> delete </i>
              </span>
              <!-- <td>{{ customer.mobile }}</td>
                <td>{{ customer.role }}</td> -->
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <svg-icon
          src="assets/users.svg"
          [svgStyle]="{ 'width.px': 15 }"
        ></svg-icon>
        <span>Guest List</span>
      </ng-template>

      <table class="mat-elevation-z8" matSort>
        <thead>
          <tr class="vq-header">
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
          </tr>
        </thead>
        <tbody>
          <tr class="vq-rows" *ngFor="let guest of eventDetail.guests">
            <td>{{ guest.firstName }} {{ guest.lastName }}</td>
            <td>{{ guest.email }}</td>
            <td>{{ guest.mobile }}</td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>
