<div class="card">
    <div class="logo">
      <img src="assets/logo 2.png">
    </div>
      <div class="card1">
        <div class="col-md-6 center">
          <h2>One Purpose. One Queue.</h2>
          <p>Deliver Their Dream Wedding</p>
          <form class="text-center " #eventForm="ngForm">
            <input type="hidden" name="key" >
            <div class="row">
              <div class="col-md-12">
                <div class="form-group pt-2">

                  <mat-form-field appearance="fill">
                    <input 
                      matInput 
                      name="venqid"
                      [(ngModel)]="login.venqid"
                      (ngModelChange)="login.venqid = $event.toUpperCase()"
                      #venQId="ngModel"
                      placeholder="VenQ ID" 
                      required>
                    <mat-error *ngIf="venQId.invalid">Please Enter VenQId</mat-error>
                      </mat-form-field>
                    </div>
        
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <input 
                      matInput 
                      type="text"
                      placeholder="UserName" 
                      name="username"
                      [(ngModel)]="login.username"
                      (ngModelChange)="login.username = $event.toLowerCase()"
                      #username="ngModel"     
                      required>
                    <mat-error *ngIf="username.invalid">Please Enter username</mat-error>
                      </mat-form-field>
                    </div>
            
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <input 
                      matInput 
                      type="password"
                      placeholder="Password" 
                      name="password"
                      [(ngModel)]="login.password"
                      #password="ngModel"
                      
                    required>
                    <mat-error *ngIf="password.invalid">Please Enter Password</mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="error" style="color:red" >Invalid Credentials</mat-error>
                    </div>
               
                </div>
              </div>
            <!-- <mat-checkbox (click)="check()" [(checked)]="!IsChecked">Keep me logged in</mat-checkbox> -->
            <div class="pt-1 submit text-left">
              <button type="submit" class="btn mat-raised-button mat-primary" (click)="onSubmit(eventForm)">Sign In</button>
            </div>
            <div class="pt-1 submit text-left">
              <a routerLink="/reset">Reset Password</a>
            </div>
          </form>
        </div>
  </div>
</div>
