import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-events-detail-timeline-print',
  templateUrl: './events-detail-timeline-print.component.html',
  styleUrls: ['./events-detail-timeline-print.component.scss'],
})
export class EventsDetailTimelinePrintComponent implements OnInit {
  eventId: string;
  eventDetail: any;
  timezoneOffset: number;
  constructor(private router: ActivatedRoute, private venueSvc: VenueService) {}

  ngOnInit() {
    // const appHeader = document.getElementById("appHeader");
    // appHeader.style.display = "none";
    this.timezoneOffset = new Date().getTimezoneOffset() * 60000;
    this.router.params.subscribe((res) => {
      this.eventId = res.id;
      this.event();
    });
  }

  event() {
    this.venueSvc.event(this.eventId).subscribe((res: any) => {
      console.log(res.data.event);
      if (res.data.event.coordinator != null) {
        this.eventDetail = res['data']['event'];
      } else {
        this.eventDetail = res['data']['event'];
        this.eventDetail.coordinator = '';
      }
    });
  }
  getUniqueDates() {
    const dates = [];
    if (this.eventDetail.timeline && Array.isArray(this.eventDetail.timeline)) {
      this.eventDetail.timeline.forEach((tl) => {
        const d = new Date(new Date(tl.onTime).getTime() + this.timezoneOffset);
        const dp = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
        if (!dates.includes(dp)) {
          dates.push(dp);
        }
      });
    }

    return dates.sort((a, b) => (a > b ? 1 : -1));
  }

  getTimelineItemsDate(dy: string) {
    const dayParts = dy.split('-');
    const arr = this.eventDetail.timeline.filter((tl) => {
      const d = new Date(new Date(tl.onTime).getTime() + this.timezoneOffset);
      return (
        d.getFullYear() === parseInt(dayParts[0], 10) &&
        d.getMonth() === parseInt(dayParts[1], 10) &&
        d.getDate() === parseInt(dayParts[2], 10)
      );
    });
    return arr.sort((a, b) => {
      return new Date(a.onTime).getTime() - new Date(b.onTime).getTime();
    });
  }
}
