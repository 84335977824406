import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss'],
})
export class VenueComponent implements OnInit {
  venue: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private venueSvc: VenueService
  ) {}

  ngOnInit() {
    this.venueSvc
      .venue(this.activatedRoute.snapshot.params.id)
      .subscribe((res) => {
        this.venue = res['data']['venue'];
      });
  }
}
