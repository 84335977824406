import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InviteComponent } from './vendor/modals/invite/invite.component';
import { ComponentsModule } from './components/components.module';
import { HomeComponent } from './pages/home/home.component';
import { UsersComponent } from './pages/users/users.component';
import { EventsComponent } from './pages/events/events.component';
import { SpacesComponent } from './pages/spaces/spaces.component';
import { HeaderComponent } from './pages/header/header.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { EventCreateComponent } from './pages/event-create/event-create.component';
import { UsersDetailComponent } from './pages/users-detail/users-detail.component';
import { EventsDetailComponent } from './pages/events-detail/events-detail.component';
import { SpacesDetailComponent } from './pages/spaces-detail/spaces-detail.component';
import { VendorsDetailComponent } from './pages/vendors-detail/vendors-detail.component';
import { RequestsDetailComponent } from './pages/requests-detail/requests-detail.component';
import { CustomersDetailComponent } from './pages/customers-detail/customers-detail.component';
import { VenueComponent } from './pages/venue/venue.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EnterOtpComponent } from './pages/enter-otp/enter-otp.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { EventsDetailTimelinePrintComponent } from './pages/events-detail-timeline-print/events-detail-timeline-print.component';
import { SearchvenueComponent } from './pages/searchvenue/searchvenue.component';

import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';
import { SpacesService } from './services/spaces.service';
import { CustomersService } from './services/customers.service';

import { environment } from '../environments/environment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    EventsDetailComponent,
    UsersComponent,
    VendorsComponent,
    VendorsDetailComponent,
    CustomersComponent,
    CustomersDetailComponent,
    HomeComponent,
    HeaderComponent,
    SignInComponent,
    EventCreateComponent,
    SpacesComponent,
    SpacesDetailComponent,
    RequestsComponent,
    RequestsDetailComponent,
    UsersDetailComponent,
    EventsComponent,
    SearchvenueComponent,
    VenueComponent,
    ProfileComponent,
    InviteComponent,
    EnterOtpComponent,
    ResetPasswordComponent,
    EventsDetailTimelinePrintComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ApolloModule,
    // HttpLinkModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    AngularEditorModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true,
    }),
  ],
  providers: [
    AuthService,
    EventsService,
    SpacesService,
    CustomersService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphQL.serverUri,
          }),
        };
      },
      deps: [HttpLink],
    },
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
