<mat-toolbar>
    <div class="input-group">
      <input type="text" class="form-control" [(ngModel)]="searchParam" (keyup)="searchUser()" placeholder="Search by User Name">
      <div class="input-group-append">
        <button (click)="searchUser()" class="btn btn-secondary" type="button">
          <i class="material-icons">search</i>
        </button>
      </div>
    </div>

  </mat-toolbar>
<div class="p-3">
  <div class="text-right">
      <a routerLink='/user/new'><i class="material-icons">add_circle</i><p class="start">Add User</p></a>
  </div>
  <table class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr class="vq-header">
        <th mat-sort-header="username">UserName</th>
        <th mat-sort-header="firstName">Full Name</th>
        <th mat-sort-header="email">Email</th>
        <th mat-sort-header="role">Role</th>
        <th mat-sort-header="status">Status</th>
        <th *ngIf="userService?.user?.role=='admin'">Action</th>
      </tr>
    </thead>
      
    <tbody>
        <tr class="vq-rows" *ngFor="let user of users">
            <td><a [routerLink]="['/user/'+user.id]"> {{ user.username }}</a></td>
            <td>{{user.firstName}} {{user.lastName}}</td>
            <td>{{user.email}}</td>
            <td>{{user.role}}</td>
            <td>{{user.status}}</td>
            <td *ngIf="userService?.user?.role=='admin'"> 
              <span (click)="deleteUser(user.id)" class="delete">
                Delete <i class="material-icons">
                  backspace
                  </i>
              </span>
              <span [routerLink]="['/user/'+user.id]" class="update ml-1">
                Update <i class="material-icons">
                    history
                    </i>
              </span>
              </td>
          </tr>
    </tbody>
      
    </table>
  </div>