import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dateplustime-picker',
  templateUrl: './dateplustime-picker.component.html',
  styleUrls: ['./dateplustime-picker.component.scss']
})
export class DateplustimePickerComponent implements OnInit {
@Input() current;
@Output() datetime;
hours = [1,2,3,4,5,6,7,8,9,10,11,12]
mins = ["00","05","10","15","20","25","30","45","50","55"]
ampm = ["AM", "PM"]
  constructor() { }

  ngOnInit(): void {
  }

}
