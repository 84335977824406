<div class="p-3">
<mat-card class="example-card">
  <mat-card-header>
  <div mat-card-avatar class="example-header-image">
    <img  [src]="venue.logo ? venue.logo : '../assets/sponsor-your-300x150.png'" mat-card-image>
  </div>

  <mat-card-title slot="end">{{ venue.name }}</mat-card-title>

  <mat-card-subtitle> {{ venue.yearEstablished }} -  {{ venue.status }}</mat-card-subtitle>

  <mat-card-subtitle> {{ venue.address }}, {{ venue.addressCity }}, {{ venue.addressState }} </mat-card-subtitle>
  <mat-card-subtitle>{{ venue.description }}</mat-card-subtitle>
  </mat-card-header>
</mat-card>
</div>