import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-print-view-timeline',
  templateUrl: './print-view-timeline.component.html',
  styleUrls: ['./print-view-timeline.component.scss'],
})
export class PrintViewTimelineComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PrintViewTimelineComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog
  ) // public venueSvc:VenueService,
  // public eventSvc:EventsService,
  // public datepipe:DatePipe
  {}

  ngOnInit() {}
}
