import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { VenueService } from 'app/services/venue.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vendors-detail',
  templateUrl: './vendors-detail.component.html',
  styleUrls: ['./vendors-detail.component.scss'],
})
export class VendorsDetailComponent implements OnInit {
  hide = false;
  image: any;
  venqID = '';
  vendorId: any = '';
  vendorDetail: any = {};
  found = true;
  vendors: any = [];
  constructor(
    private venueSvc: VenueService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.vendorList();
  }
  vendorList() {
    this.venueSvc.venueVendors().subscribe((res) => {
      this.vendors = res['data']['venue']['vendorBook'];
    });
  }

  onSubmit(eventForm: NgForm) {
    if (eventForm.valid) {
      const payload = {
        vendor: this.vendorId,
        status: 'active',
        // username: controls.customer.value,
        // firstName: controls.vendorType.value,
        // lastName: controls.contactPerson.value,
        // email: controls.availability.value,
        // password: controls.phoneNo.value,
        // role: controls.priceRange.value,
        // status: controls.status.value,
        // venue:this.userService.user.venue.id
      };

      this.venueSvc.createVenueVendor(payload).subscribe((res) => {
        this.venueSvc.showSuccess('Vendor Added Successfully');

        this.router.navigate(['/vendors']);
      });
    }
  }
  searchVendor() {
    this.venueSvc.searchVendor(this.venqID.toUpperCase()).subscribe((res) => {
      if (res['data']['vendorVenqId'].length > 0) {
        const index = this.vendors.findIndex(
          (vendor) => vendor.vendor.id === res['data']['vendorVenqId'][0]['id']
        );
        if (index > -1) {
          this.venueSvc.showWarning('Vendor already added');
          //  alert()
        } else {
          this.vendorDetail.name = res['data']['vendorVenqId'][0]['name'];
          this.vendorDetail.address = res['data']['vendorVenqId'][0]['address'];
          this.vendorDetail.category =
            res['data']['vendorVenqId'][0]['category']['name'];
          this.vendorId = res['data']['vendorVenqId'][0]['id'];
          this.found = true;
        }
      } else {
        this.vendorId = '';
        this.vendorDetail.name = '';
        this.vendorDetail.address = '';
        this.vendorDetail.category = '';
        this.found = false;
      }
    });
  }
}
