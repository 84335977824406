<mat-toolbar class="bg-white no-print" *ngIf="authService.isLoggedin" name="appHeader" id="appHeader">
  <mat-toolbar-row>
    <button mat-icon-button class="menu_b" *ngIf="isAdmin">
      <mat-icon class="menu" (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <img class="logo" src="assets/logo_final.jpg">
    <span class="menu-spacer"></span>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="container pt-4 m d-flex justify-content-beetwen flex-wrap">
            <div routerLink="/events" routerLinkActive #rlap="routerLinkActive">
              <button mat-button *ngIf='!rlap.isActive else nonactivep'>
               <i class="fal fa-calendar-check"></i> Events
              </button>
              <ng-template #nonactivep>
                <button mat-button color="primary">
                  <i class="fal fa-calendar-check"></i> Events
                </button>
              </ng-template>
            </div>
            <div routerLink="/spaces" routerLinkActive #rlac="routerLinkActive">
              <button mat-button *ngIf='!rlac.isActive; else nonactivec'>
                <i class="fal fa-building"></i>  Event Space
              </button>
              <ng-template #nonactivec>
                <button mat-button color="primary">
                  <i class="fal fa-building"></i>  Event Space
                </button>
              </ng-template>
            </div>
            <div routerLink="/vendors" routerLinkActive #rlaf="routerLinkActive">
              <button mat-button *ngIf='!rlaf.isActive; else nonactivef'>
                 <i class="fal fa-address-book"></i>  Vendorbook
              </button>
              <ng-template #nonactivef>
                <button mat-button color="primary">
                   <i class="fal fa-address-book"></i>  Vendorbook
                </button>
              </ng-template>
            </div>
            <div routerLink="/searchvenue" routerLinkActive #rlaa="routerLinkActive">
              <button mat-button *ngIf='!rlaa.isActive; else nonactivea'>
                 <i class="fal fa-search"></i>  Search Venue
              </button>
              <ng-template #nonactivea>
                <button mat-button color="primary">
                   <i class="fal fa-search"></i>  Search Venue
                </button>
              </ng-template>
            </div>
            <div routerLink="/profile" routerLinkActive #rlar="routerLinkActive">
              <button mat-button *ngIf='!rlar.isActive; else nonactiver'>
                <i class="fal fa-cog"></i> Profile
              </button>
              <ng-template #nonactiver>
                <button mat-button color="primary">
                  <i class="fal fa-cog"></i> Profile
                </button>
              </ng-template>
            </div>
            <div routerLink="/users" routerLinkActive #rlav="routerLinkActive">
              <button mat-button *ngIf='!rlav.isActive; else nonactivev'>
                <i class="fal fa-users"></i>  Users
              </button>
              <ng-template #nonactivev>
                <button mat-button color="primary">
                  <i class="fal fa-users"></i>  Users
                </button>
              </ng-template>
            </div>

            <div (click)="goToLink('https://www.venqapp.com/help-venue');">
              <button mat-button >
                <i class="fa fa-question-circle" aria-hidden="true" style="margin-right:10px;"></i>Help
              </button>
             </div>
            <button *ngIf="authService.isLoggedin" (click)="logout()" mat-button class="logout">
              <i class="fal fa-power-off"></i>
              Logout
            </button>
          </div>

        </div>
      </div>
    </div>
  </mat-toolbar-row>

</mat-toolbar>
<mat-sidenav-container *ngIf="isAdmin">
  <mat-sidenav #sidenav >
    <mat-nav-list *ngIf="authService.isLoggedin">

      <a mat-list-item [routerLink]="'/events'">
        <i class="fal fa-calendar-check"></i> Events2
      </a>
      <a mat-list-item [routerLink]="'/spaces'">
        <i class="fal fa-building"></i> Event Space
      </a>
      <a mat-list-item [routerLink]="'/vendors'">
        <i class="fal fa-address-book"></i> Vendorbook
      </a>
      <a mat-list-item [routerLink]="'/searchvenue'">
        <i class="fal fa-search"></i> Search Venue
      </a>
      <a mat-list-item [routerLink]="'/profile'" *ngIf="isAdmin">
        <i class="fal fa-cog"></i> Profile
      </a>
      <a mat-list-item [routerLink]="'/users'">
        <i class="fal fa-users"></i> Users
      </a>
      <!-- isAdmin Not working... levar can you please check this-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 100vh;">

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>