import { Component, OnInit, Optional, Inject, Input, InjectionToken } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { VenueService } from 'app/services/venue.service';
import { UserService } from 'app/services/user.service';
import { EventsService } from '@app/services/events.service';

@Component({
  selector: 'app-add-event-vendor',
  templateUrl: './add-event-vendor.component.html',
  styleUrls: ['./add-event-vendor.component.scss'],
})
export class AddEventVendorComponent implements OnInit {
  @Input() eventVendors: any;
  found = true;
  vendorDetail: any = {};
  venqID: any = '';
  vendorId: any = '';
  venueVendors: any = [];

  close = 'helsdfsdfsdlo';
  constructor(
    public dialogRef: MatDialogRef<AddEventVendorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private venueSvc: VenueService,
    private userSvc: UserService,
    private eventService: EventsService
  ) {}

  ngOnInit() {
    this.vendorList();
    this.getEventVendors()
  }
  onVendorChange(event) {
    this.venqID = event.value;
    this.searchVendor();
  }
  vendorList() {
    this.venueSvc.venueVendors().subscribe((res) => {
      this.venueVendors = res['data']['venue']['vendorBook'];
      

      // this.sortData({ active: "vendorName", direction: "asc" })
    });
  }
  getEventVendors() {
    this.eventService.eventVendors(this.data.eventId).subscribe((res) => {
      this.eventVendors = res['data']['eventVendors'];
      // this.vendors = this.eventVendors
      // this.selectedVendors = this.vendors.filter(
      //   (vendor) => vendor.status === 'Selected'
      // );

    });
  }
  sortData(e) {
    if (e.direction === 'asc') {
      if (e.active === 'vendorName') {
        this.venueVendors.sort((a, b) =>
          a.vendor.name.localeCompare(b.vendor.name)
        );
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.venueVendors.sort((a, b) => {
          return a[e.active] - b[e.active];
        });
      } else {
        this.venueVendors.sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'vendorName') {
        this.venueVendors.sort((a, b) =>
          b.vendor.name.localeCompare(a.vendor.name)
        );
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.venueVendors.sort((a, b) => {
          return b[e.active] - a[e.active];
        });
      } else {
        this.venueVendors.sort(this.dynamicSort('-' + e.active));
      }
    }
  }
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  searchVendor() {
    this.venueSvc.searchVendor(this.venqID.toUpperCase()).subscribe((res) => {
      console.log(res.data, "RTRN")
      if (res['data']['vendorVenqId'].length > 0) {
        const index = this.eventVendors.findIndex(
          (vendor) => vendor.vendor.id === res['data']['vendorVenqId'][0]['id']
        );
        if (index > -1) {
          this.venueSvc.showWarning('Vendor already added');
          this.vendorId = '';
          this.found = true;
          //  alert()
        } else {
          this.vendorDetail.name = res['data']['vendorVenqId'][0]['name'];
          this.vendorDetail.address = res['data']['vendorVenqId'][0]['address'];
          this.vendorDetail.category =
            res['data']['vendorVenqId'][0]['category']['name'];
          this.vendorDetail.vendorType =
            res['data']['vendorVenqId'][0]['category']['id']; // to use while creating eventVendor
          this.vendorId = res['data']['vendorVenqId'][0]['id'];
          this.found = true;
        }
      } else {
        this.vendorId = '';
        this.vendorDetail.name = '';
        this.vendorDetail.address = '';
        this.vendorDetail.category = '';
        this.found = false;
      }
    });
  }

  onSubmit(eventForm: NgForm) {
    if (eventForm.valid) {
      const payload = {
        vendor: this.vendorId,
        status: 'Accepted',
        event: this.userSvc.currentEvent.id,
        vendorType: this.vendorDetail.vendorType,
      };

      this.venueSvc.createEventVendor(payload).subscribe(
        (res) => {
          this.venueSvc.showSuccess('Vendor Added to Event');
          this.dialogRef.close(true);
        }
        // (error) => {
        //   this.venueSvc.showFailure('There was some problem adding vendor');
        //   this.dialogRef.close();
        // }
      );
    }
  }
}
