import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class AuthService {
  token: string;
  loggedUser;
  dbUser;
  isLoggedin: boolean = localStorage.getItem('token') ? true : false;

  constructor(private router: Router, public apollo: Apollo) {}

  signIn(payload: any) {
    const variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($data: loginInput!) {
          venueUserLogin(data: $data) {
            status
            token
            user {
              id
              email
              role
              venue {
                id
                venqId
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables,
    }).valueChanges;
  }
}

// query{
//   venueUserLogin(data:{username:"Shivani",password:"test@123",venue:"FHRANCH"}){

//     status
//     token
//     user{
//       id
//       username
//       password
//       venue {
//         id
//         venqId
//       }

//     }

//   }
//   }
