import { Component, OnInit, Optional, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { VenueService } from 'app/services/venue.service';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'app-event-note',
  templateUrl: './event-note.component.html',
  styleUrls: ['./event-note.component.scss'],
})
export class EventNoteComponent implements OnInit {
  minDateValue: Date = new Date();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '0',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'redo',
        'italic',
        'justifyFull',
        ,
        'heading',
        'indent',
      ],
      ['fontSize', 'customClasses', 'insertVideo'],
    ],
  };
  close = false;
  dateValue: any;

  constructor(
    public dialogRef: MatDialogRef<EventNoteComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    public venueSvc: VenueService,
    public eventSvc: EventsService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.dateValue = new Date(this.data.date);
  }
  onAddNote(noteForm: NgForm, next: boolean = false) {
    if (noteForm.valid) {
      const controls = noteForm.controls;
      const isNext = next;

      const obj = {
        note: controls.note.value,
        access: controls.access.value ? controls.access.value : 'all',
        event: this.data.event,
        userType: 'venue',
        venueUser: this.data.venueUserId,
      };
      const date = new Date(controls.dateValue.value);

      obj['date'] = date.getTime() / 1000;
      console.log(obj);

      this.eventSvc.createEventNote(obj).subscribe((res) => {
        this.venueSvc.showSuccess('Note Added Successfully');

        this.dialogRef.close(isNext);
      });
    }
  }
  onUpdateNote(noteForm: NgForm) {
    if (noteForm.valid) {
      const controls = noteForm.controls;

      const obj = {
        id: this.data.id,
        access: controls.access.value ? controls.access.value : 'all',
        event: this.data.event,
        note: controls.note.value,
      };
      const date = new Date(controls.dateValue.value);
      obj['date'] = date.getTime() / 1000;
      console.log(obj);

      this.eventSvc.updateEventNote(obj).subscribe((res) => {
        this.venueSvc.showSuccess('Note Updated Successfully');
        this.dialogRef.close();
      });
    }
  }
}
