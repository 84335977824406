import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { UserService } from '../../services/user.service';
import { EventsService } from '../../services/events.service';
import { VenueService } from '../../services/venue.service';
import { CustomersService } from '../../services/customers.service';
import { DeleteComponent } from '../../components/delete/delete.component';
import { CustomerComponent } from '../../components/customer/customer.component';
import { AddEventVendorComponent } from '../../components/add-event-vendor/add-event-vendor.component';
import { ImagepreviewComponent } from '../../components/imagepreview/imagepreview.component';
import { TimelineaddmodalComponent } from '../../components/timelineaddmodal/timelineaddmodal.component';
import { EventNoteComponent } from '../../components/event-note/event-note.component';

import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss'],
  // providers: [
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

  //   {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  // ],
})
export class EventsDetailComponent implements OnInit {
  minDateValue: Date = new Date();
  file: any;
  deleteId: any = '';
  vendors: any = [];
  selectedVendors: any = [];
  dateFlag = true; // ngOninit was called twice
  eventDetail: any = {
    eventSpace: {
      id: '',
    },
    coordinator: {
      id: '',
    },
  };
  onDate: any;
  submitted = false;
  venueSpaces: any = [];
  coordinators: any = [];
  isCreate = true;

  customers: any = [];
  accessType: any = 'Access';
  accessTypeNote: any = 'Access';
  timeLineItems: any = [];
  filteredTimeLineItems: any = [];
  eventId: any = '';
  pdfGenerateFlag = false;
  eventNotes: any = [];
  constructor(
    public userSvc: UserService,
    public eventSvc: EventsService,
    public venueSvc: VenueService,
    public customerSvc: CustomersService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public datePipe: DatePipe
  ) {}

  ngOnInit() {
    // setTimeout( ()=> this.exportAsPDF(), 3000)
    if (this.route.snapshot.params.id) {
      this.eventId = this.route.snapshot.params.id;
      this.isCreate = false;
      this.event();
      this.getEventNotes();
    }
    this.getVenueSpaces();
    this.getCoordinators();
    this.eventCustomers();
    this.eventVendors();
  }
  exportAsPDF() {
    console.log('feature gone');
  }

  event() {
    this.venueSvc.event(this.eventId).subscribe({
      next: (res: any) => {
        console.log(res.data, 'EVD');
        if (res.data.event.coordinator != null) {
          Object.assign(this.eventDetail, res['data']['event']);
        } else {
          this.eventDetail = res['data']['event'];
          this.eventDetail.coordinator = '';
        }
        console.log(this.eventDetail, 'EVD');
        this.userSvc.currentEvent = this.eventDetail;
        this.customers = res['data']['event']['customers'];

        this.eventDetail['onDate'] =
          parseInt(this.eventDetail['onDate'], 10) * 1000;

        this.dateFlag = false;
        this.timeLineItems = this.eventDetail.timeline;
        this.filteredTimeLineItems = this.eventDetail.timeline;

        this.filterTimeLine();
      },
      error: (e) => {
        console.log(e);
      },
    });
  }
  getEventNotes() {
    this.eventSvc.eventNotes(this.eventId).subscribe((res) => {
      this.eventNotes = res['data']['eventNotes'];
    });
  }
  eventGuests() {
    this.eventSvc.eventNotes(this.eventId).subscribe((res) => {
      this.eventDetail.guests = res['data']['eventGuests'];
    });
  }
  eventCustomers() {
    this.eventSvc.eventCustomers(this.eventId).subscribe((res) => {
      this.customers = res['data']['eventCustomers'];
    });
  }
  eventVendors() {
    this.eventSvc.eventVendors(this.eventId).subscribe((res) => {
      //  this.eventVendors = res['data']['eventVendors'];
      this.vendors = res['data']['eventVendors']; //this.eventVendors
      this.selectedVendors = this.vendors.filter(
        (vendor) => vendor.status === 'Selected'
      );
    });
  }
  getMinutes(timeString) {
    const meridian = timeString.split(' ')[1];

    const time = timeString.split(' ')[0];
    const hours = parseInt(time.split(':')[0], 10);
    const minutes = parseInt(time.split(':')[0], 10);
    const Totalminutes =
      meridian === 'AM' ? hours + minutes : hours * 60 + minutes;
    return Totalminutes;
  }

  onAccessChange(event) {
    this.accessType = event.value === 'all' ? 'Access' : event.value;
    this.filterTimeLine();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    // this.eventDetailSubscription.unsubscribe()
  }

  cancel() {
    this.router.navigate(['/events']);
  }
  getVenueSpaces() {
    this.venueSvc.spaces().subscribe((res) => {
      this.venueSpaces = res['data']['venueSpaces'] || [];
    });
  }
  getCoordinators() {
    this.venueSvc.users().subscribe((res) => {
      this.coordinators = res['data']['venue']['venueUsers'];
    });
  }

  OptionChanged() {
    console.log('set Coordinator==>', this.eventDetail.coordinator);
  }

  onSubmit(eventForm: NgForm) {
    if (eventForm.valid) {
      const controls = eventForm.controls;
      const payload = {
        coordinator: controls.eventCoordinator.value,
        partyName: controls.partyName.value,
        eventSpace: controls.eventSpace.value,
        eventType: controls.eventType.value,
        guestCount: controls['guestCount'].value || 0,
        startTime: controls.startTime.value,
        endTime: controls.endTime.value,
        description: controls.description.value,
        status: 'active',
      };

      if (controls.onDate.value !== undefined) {
        const date = new Date(controls.onDate.value);
        payload['onDate'] = date.getTime() / 1000; // Because it can't INT this high
      }
      this.venueSvc.createEvent(payload).subscribe((res) => {
        this.venueSvc.showSuccess('Event Added Successfully');
        this.router.navigate(['events']);
      });
    }
  }
  changeTime(event) {
    // console.log(event);
  }
  Update(eventForm: NgForm) {
    if (eventForm.valid) {
      const controls = eventForm.controls;
      const payload = {
        coordinator: controls.eventCoordinator.value,
        partyName: controls.partyName.value,
        eventSpace: controls.eventSpace.value,
        eventType: controls.eventType.value,
        guestCount: controls['guestCount'].value || 0,
        startTime: controls.startTime.value,
        endTime: controls.endTime.value,
        description: controls.description.value,

        status: 'active',
      };
      if (controls.onDate.value !== undefined) {
        const date = new Date(controls.onDate.value);
        payload['onDate'] = date.getTime() / 1000; // Because it can't INT this high
        // console.log(date.getTime().valueOf())
      }

      // return false
      this.venueSvc.updateEvent(this.eventId, payload).subscribe((res) => {
        this.venueSvc.showSuccess('Event Info Updated Successfully');
        this.router.navigate(['events']);
      });
    }
  }
  onFileInput(event) {
    if (event.target.files[0]) {
      const reader = new FileReader();
      this.file = event.target.files[0];
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(event.target.files[0]);
    }
  }

  removeImage(photoId: string, eventId: string, index: number) {
    const payload = {
      photoId,
      eventId,
    };
    this.venueSvc.deleteEventPhoto(payload).subscribe((res) => {
      this.venueSvc.showSuccess('Floor Plan Removed');
      this.event();
      // this.eventDetail.floorPlans.splice(index, 1);
    });
  }

  handleReaderLoaded(e) {
    const payload = {
      id: this.eventId,
      objectType: 'floorPlan',
      objectId: this.eventId,
      imageEncoded: 'data:image/png;base64,' + btoa(e.target.result),
      imageName: this.file.name.split('.').slice(0, -1).join('.'),
    };
    this.venueSvc.uploadImage(this.eventId, payload).subscribe((res) => {
      this.venueSvc.showSuccess('Floor Plan Uploaded Successfully');
      this.event();
      //  this.eventDetail.floorPlans = res["data"]["createEventPhoto"]["floorPlans"]
    });
  }

  updatEvent(url: string) {
    const payload = {
      id: this.eventId,
      venue: this.userSvc.user.venue.id,
      partyName: this.eventDetail.partyName,
      floorPlan: url,
    };
    this.venueSvc.updateEvent(this.eventId, payload).subscribe((res) => {
      this.venueSvc.showSuccess('Floor Plan Uploaded Successfully');
    });
  }
  deleteTimeLine(id) {
    this.deleteId = id;
    this.openDeleteTimeLineConfirmation();
  }
  deleteUser(id) {
    this.deleteId = id;
    this.openDeleteUserConfirmation();
  }
  deleteEventVendor(id) {
    this.deleteId = id;
    this.openDeleteEventVendorConfirmation();
  }
  deleteEventNote(id) {
    this.deleteId = id;
    this.openDeleteEventNoteConfirmation();
  }

  //////////////////////////////// Show Modal Functions/////////////////////////////////////////////

  openDeleteEventVendorConfirmation() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      // data: { name: '', email: '',mobile:'',role:'',primaryContact:false,isCreate:true }
      data: { userId: this.deleteId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteEventVendor(this.deleteId).subscribe((res) => {
          this.event();
          this.eventVendors();
          this.venueSvc.showSuccess('Event Vendor deleted Successfully');
        });
      }
    });
  }
  openDeleteEventNoteConfirmation() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      // data: { name: '', email: '',mobile:'',role:'',primaryContact:false,isCreate:true }
      data: { userId: this.deleteId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.eventSvc.deleteEventNote(this.deleteId).subscribe((res) => {
          this.getEventNotes();
          this.venueSvc.showSuccess('Note deleted Successfully');
        });
      }
    });
  }

  openDeleteUserConfirmation() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      // data: { name: '', email: '',mobile:'',role:'',primaryContact:false,isCreate:true }
      data: { userId: this.deleteId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.customerSvc.deleteCustomer(this.deleteId).subscribe((res) => {
          this.event();
          this.venueSvc.showSuccess('Customer deleted Successfully');
        });
      }
    });
  }

  openDialogCustomer() {
    const dialogRef = this.dialog.open(CustomerComponent, {
      // data: { name: '', email: '',mobile:'',role:'CustomerComponent',primaryContact:false,isCreate:true }
      data: { name: '', email: '', isCreate: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        delete result['isCreate'];
        result['id'] = this.eventId;

        this.venueSvc.linkEventCustomer(result).subscribe((res) => {
          this.customers = res['data']['linkEventCustomer']['customers'];
          this.venueSvc.showSuccess('Customer Linked to Event Successfully');
        });
      }
    });
  }

  showAddVendor() {
    const dialogRef = this.dialog.open(AddEventVendorComponent, {
      // data: { image: this.eventDetail.floorPlan }
      data: { eventId: this.eventId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.event();
        this.eventVendors();
      }
    });
  }

  openPreview(floorPlan: string) {
    const dialogRef = this.dialog.open(ImagepreviewComponent, {
      data: { image: floorPlan },
    });
  }

  openDeleteTimeLineConfirmation() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { timelineId: this.deleteId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // return false

      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteTimelineItem(this.deleteId).subscribe((res) => {
          this.event();
          this.venueSvc.showSuccess('TimeLine Item Removed Successfully');
        });
      }
    });
  }

  createTimeLine() {
    const dialogRef = this.dialog.open(TimelineaddmodalComponent, {
      data: {
        item: '',
        onTime: this.eventDetail.onDate,
        description: '',
        access: '',
        event: this.eventId,
        where: '',
        eventDate: moment(this.eventDetail.onDate).format('YYYY-MM-DD'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createTimeLine();
      }
      this.event();
    });
  }
  editTimeLine(timeline) {
    const obj = {
      onTime: timeline.onTime,
      id: timeline.id,
      item: timeline.item,
      description: timeline.description,
      event: this.eventId,
      access: timeline.access,
      where: timeline.where,
      eventDate: moment(this.eventDetail.onDate).format('YYYY-MM-DD'),
    };

    const dialogRef = this.dialog.open(TimelineaddmodalComponent, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.event();
    });
  }
  showAddNote() {
    const dialogRef = this.dialog.open(EventNoteComponent, {
      data: {
        date: null,
        note: '',
        access: '',
        event: this.eventId,
        venueUserId: this.userSvc.user.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.showAddNote();

      this.getEventNotes();
    });
  }
  editNote(note) {
    const date = new Date(note.date);
    const dialogRef = this.dialog.open(EventNoteComponent, {
      data: {
        id: note.id,
        date,
        note: note.note,
        access: note.access,
        event: this.eventId,
        venueUserId: this.userSvc.user.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.showAddNote();

      this.getEventNotes();
    });
  }
  showTimelinePrintView() {
    const obj = {
      data: this.timeLineItems,
    };
    // const dialogRef = this.dialog.open(PrintViewTimelineComponent, {...obj})
    this.router.navigate(['event', this.eventId, 'timeline']);
  }
  //////////////////////////////// Show Modal Functions/////////////////////////////////////////////

  sendConfirmation() {
    const vendorIds = [];

    this.selectedVendors.map((vendor) => {
      vendorIds.push(vendor.vendor.id);
    });
    this.venueSvc.sendConfirmationVendors(vendorIds).subscribe((res) => {
      console.log(res);
    });
  }
  convertDate(strDate: string) {
    const i = parseInt(strDate, 10);

    return new Date(i * 1000);
  }

  // sorting Functions

  sortData(e) {
    if (e.direction === 'asc') {
      if (e.active === 'onTime') {
        [...this.filteredTimeLineItems].sort((a, b) => {
          return new Date(a.onTime).getTime() - new Date(b.onTime).getTime();
        });
      } else {
        [...this.filteredTimeLineItems].sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'onTime') {
        [...this.filteredTimeLineItems].sort((a, b) => {
          return new Date(a.onTime).getTime() - new Date(b.onTime).getTime();
        });
      } else {
        [...this.filteredTimeLineItems].sort(this.dynamicSort('-' + e.active));
      }
    }
  }
  sortDataVendors(e) {
    if (e.direction === 'asc') {
      if (e.active === 'name') {
        this.vendors.sort((a, b) => a.vendor.name.localeCompare(b.vendor.name));
      } else if (e.active === 'type') {
        this.vendors.sort((a, b) =>
          a.vendorType.name.localeCompare(b.vendorType.name)
        );
      } else {
        this.vendors.sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'name') {
        this.vendors.sort((a, b) => b.vendor.name.localeCompare(a.vendor.name));
      } else if (e.active === 'type') {
        this.vendors.sort((a, b) =>
          b.vendorType.name.localeCompare(a.vendorType.name)
        );
      } else {
        this.vendors.sort(this.dynamicSort('-' + e.active));
      }
    }
  }
  sortDataClients(e) {
    if (e.direction === 'asc') {
      this.customers.sort(this.dynamicSort(e.active));
    } else {
      this.customers.sort(this.dynamicSort('-' + e.active));
    }
  }

  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  filterTimeLine() {
    if (this.accessType !== 'Access') {
      this.filteredTimeLineItems = this.timeLineItems.filter(
        (timeline) => timeline.access === this.accessType
      );
    } else {
      this.filteredTimeLineItems = this.timeLineItems;
    }
    this.sortData({ active: 'onTime', direction: 'asc' });
  }
  sortDataNote(e) {}
}
