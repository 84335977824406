<div class="card">
    <div class="logo">
      <img src="assets/logo 2.png">
    </div>
      <div class="card1">
        <div class="col-md-6 center" *ngIf="showEmail">
          <h2>Enter Email to get the passcode</h2>
          <!-- <p>Deliver Their Dream Wedding</p> -->
          <form class="text-center " #eventForm="ngForm" >
            <input type="hidden" name="key" >
            <div class="row">
              <div class="col-md-12">
                <div class="form-group pt-2">
                  <mat-form-field appearance="fill">
                    <input 
                      matInput 
                      name="email"
                      [(ngModel)]="email"
                      #emailAddress="ngModel"
                      placeholder="Email" 
                      required>
                    <mat-error *ngIf="emailAddress.invalid">Please Enter Email</mat-error>
                      </mat-form-field>
                    </div>
        
               
                </div>
              </div>
            <!-- <mat-checkbox (click)="check()" [(checked)]="!IsChecked">Keep me logged in</mat-checkbox> -->
            <div class="pt-1 submit text-left">
              <button type="submit" class="btn mat-raised-button mat-primary" (click)="onSubmit(eventForm)">Send Passcode</button>
            </div>
            
          </form>
        </div>
        <div class="col-md-6 center" *ngIf="!showEmail">
            <h2>Enter the passcode</h2>
            <!-- <p>Deliver Their Dream Wedding</p> -->
            <form class="text-center " #passCodeForm="ngForm">
              <input type="hidden" name="key" >
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group pt-2">
                    <mat-form-field appearance="fill">
                      <input 
                        matInput 
                        name="passcode"
                        [(ngModel)]="passcode"
                        
                        #Otp="ngModel"
                        placeholder="Passcode" 
                        required>
                      <mat-error *ngIf="Otp.invalid">Please Enter Passcode</mat-error>
                        </mat-form-field>
                      </div>
          
                 
                  </div>
                </div>
              <!-- <mat-checkbox (click)="check()" [(checked)]="!IsChecked">Keep me logged in</mat-checkbox> -->
              <div class="pt-1 submit text-left">
                <button type="submit" class="btn mat-raised-button mat-primary" (click)="onSubmitPassCode(passCodeForm)" >Verify</button>
              </div>
              
            </form>
          </div>
  </div>
</div>
