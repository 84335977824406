<div class="outterContainer">
  <div class="headerContainer">
    <div class="headerName">
      {{ eventDetail.partyName }}
    </div>
  </div>
  <div *ngFor="let dy of getUniqueDates()" class="innerContainer">
    <h2>{{ dy | date: 'MMM dd yyyy' }}</h2>
    <div *ngFor="let tl of getTimelineItemsDate(dy)" class="tlContainer">
      <div class="tlHeader">
        <div class="tlTime">
          {{ tl.onTime | date: 'h:mm' }}
        </div>
        <div class="tlLocation">{{ tl.where }}</div>
      </div>
      <div class="tlWhat">
        <b>{{ tl.item }}</b> <br />
        <span [innerHtml]="tl.description"></span>
      </div>
    </div>
  </div>
</div>
