<div>
<h1>
    {{data| json}}
</h1>

 <div *ngFor="let tl of data">
    Time: {{tl.onTime}} <br>
    Item: {{tl.item}}<br>
    where: {{tl.where}}<br>
    access: {{tl.access}}<br>
    Item: {{tl.item}}<br>
    Item: {{tl.item}}<br>
 </div>
</div>
