import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimelineaddmodalComponent } from '../timelineaddmodal/timelineaddmodal.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  close = 'helsdfsdfsdlo';
  constructor(
    public dialogRef: MatDialogRef<TimelineaddmodalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  onSubmitCustomer(customerForm: NgForm) {}

  onupdateCustomer(customerForm: NgForm) {}
}
