import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(public apollo: Apollo) {}

  private handleError(error) {}
  list() {
    return this.apollo.watchQuery({
      query: gql`
        {
          events {
            id
            partyName
          }
        }
      `,
    }).valueChanges;
  }

  public create(obj: any) {
    const variables = {
      data: {
        ...obj,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateEventInput!) {
          createEvent(data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }
  createEventNote(obj: any) {
    const variables = {
      data: {
        ...obj,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateEventNoteInput!) {
          createEventNote(data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }
  updateEventNote(obj: any) {
    const id = obj['id'];
    delete obj['id'];
    const variables = {
      id,
      data: {
        ...obj,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: updateEventNoteInput) {
          updateEventNote(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateTimeLineItem(payload) {
    const id = payload['id'];
    delete payload['id'];

    const variables = {
      id,
      data: {
        ...payload,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: updateTimelineItemInput!, $id: String!) {
          updateTimelineItem(data: $data, id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }
  createTimeLineItem(timeline: any) {
    delete timeline['isCreate'];
    const variables = {
      data: {
        ...timeline,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateTimelineItemInput!) {
          createTimelineItem(data: $data) {
            id
            onTime
            access
            item
            description
          }
        }
      `,
      variables,
    });
  }
  eventNotes(eventId) {
    return this.apollo.watchQuery({
      query: gql`
          {
            eventNotes(id:"${eventId}"){
              id
              date
              access
              note
              userType
            }
          }`,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

 public eventGuests(eventId){
    return this.apollo.watchQuery({
      query: gql`
          {
            eventGuests(id:"${eventId}"){
                firstName
                lastName
                email
                mobile
                status             
            }
          }`,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  public eventCustomers(eventId){
    return this.apollo.watchQuery({
      query: gql`
          {
            eventCustomers(id:"${eventId}"){
 
                id
                role rsvpStatus secondaryRole
                user { id name email}     
            }
          }`,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public eventVendors(eventId){
    return this.apollo.watchQuery({
      query: gql`
          {
            eventVendors(id:"${eventId}"){
             
                id
                name
                email
                status
                vendorType{
                  name
                  id
                }
                vendor{ id name  }
                     
            }
          }`,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  deleteEventNote(id) {
    const variables = {
      id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteEventNote(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }
}
