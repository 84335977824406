import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class VenueService {
  venueV;
  constructor(
    private apollo: Apollo,
    public userSvc: UserService,
    private toastr: ToastrService
  ) {}

  showSuccess(message) {
    this.toastr.success('Success', message);
  }
  showFailure(message) {
    this.toastr.error('Error', message);
  }
  showWarning(message) {
    this.toastr.warning('Warning', message);
  }

  public users() {
    return this.apollo.watchQuery({
      query: gql`
           {
            venue(id:"${this.userSvc.user.venue.id}") {
              id
              venueUsers{
                id
              username
              firstName
              lastName
              email
             
              role
            
              status
              }
             
            }
          }
         `,
      fetchPolicy: 'network-only',
    }).valueChanges;
    //  })
    //   .valueChanges
  }

  public categories() {
    return this.apollo.watchQuery({
      query: gql`
        {
          venueCategories {
            id
            name
          }
        }
      `,
      fetchPolicy: 'network-only',
    }).valueChanges;
    //  })
    //   .valueChanges
  }
  venue(id) {
    return this.apollo.watchQuery({
      query: gql`
        {
          venue(id:"${id}") {
           id
           name
           venqId
           address
           yearEstablished
           status
           description
           office
           address
           addressCity
           addressState
           logo
           instagram
           lblCoordinator
           primaryCategory{ id name }
           secondaryCatgory{ id name }
           
          
         }
       }
      `,
    }).valueChanges;
  }
  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  public searchVenue(lat, lng, time, maxGuests) {
    return this.apollo.watchQuery({
      query: gql`
           {
            searchVenue(lat:${lat},lng:${lng},time:"${time}",maxGuests:${parseInt(
        maxGuests,
        10
      )}) {
              id
              name
              address
              yearEstablished
              

            }
          }
         `,
    }).valueChanges;
  }
  public venueVendors() {
    return this.apollo.watchQuery({
      query: gql`
           {
            venue(id:"${this.userSvc.user.venue.id}") {
              id
              status
              vendorBook{
                id
                vendor{
                  id
                  venqId
                  name
                  website
                  office
                  address
                  category{
                    id
                    name
                  }
                }
                
              }
             
            }
          }
         `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  public user(id: string) {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueUser(id:"${id}") {
              id
              username
              firstName
              lastName
              email
              status
              role
              password
            

            }
          }
          `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public spaces() {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueSpaces(id:"${this.userSvc.user.venue.id}") {
              id
              name
              sqft
              maxGuests
              type
            }
          }
         `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  public space(id: string) {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueSpace(id:"${id}") {
              id
              name
              sqft
              maxGuests
              type
            }
          }
         `,
    }).valueChanges;
  }

  public events() {
    return this.apollo.watchQuery({
      query: gql`
        {
         venueEvents(id:"${this.userSvc.user.venue.id}") {
           id
           partyName
           eventSpace { id name}
           onDate
           guestCount
         }
       }
      `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  public event(id: string) {
    return this.apollo.watchQuery({
      query: gql`
           {
            event(id:"${id}") {
              id
              partyName
              onDate
              eventType
              startTime
              endTime
              guestCount
              description
              eventSpace{ id  }
              coordinator{ id }
              timeline{ id onTime item description access where }
              floorPlans { 
                id
                url          note
              }
             
              
              customers{
                id
                role rsvpStatus secondaryRole
                user { id name email}
              }

            }
          }
         `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  public createEvent(payload: any) {
    const variables = {
      data: {
        ...payload,
        venue: this.userSvc.user.venue.id,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateEventInput!) {
          createEvent(data: $data) {
            id
            partyName
            onDate
            eventSpace {
              id
              name
            }
            eventType
            guestCount
          }
        }
      `,
      variables,
    });
  }

  updateSpace(id, venueSpace: any) {
    const variables = {
      id,
      data: {
        ...venueSpace,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: updateVenueSpaceInput) {
          updateVenueSpace(id: $id, data: $data) {
            id
            name
            venue {
              id
              name
            }
            sqft
            type
            maxGuests
          }
        }
      `,
      variables,
    });
  }
  createSpace(venueSpace: any) {
    const variables = {
      data: {
        ...venueSpace,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateVenueSpaceInput!) {
          createVenueSpace(data: $data) {
            id
            name
            venue {
              id
              name
            }
            name
            sqft
            type
            maxGuests
          }
        }
      `,
      variables,
    });
  }
  sendOTP(payload) {
    const variables = {
      ...payload,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($userEmail: String, $userType: String) {
          sendOTP(userEmail: $userEmail, userType: $userType) {
            userId
            status
          }
        }
      `,
      variables,
    });
  }
  verifyOTP(payload) {
    return this.apollo.watchQuery({
      query: gql`
         {
          verifyOTP(id:"${payload.id}",userType:"${payload.userType}",otp:"${payload.otp}") {
            status
            message
          }
        }
       `,
    }).valueChanges;
  }

  updatePassword(payload) {
    const variables = {
      ...payload,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String!, $userType: String!, $password: String!) {
          updatePassword(id: $id, userType: $userType, password: $password) {
            message
            status
          }
        }
      `,
      variables,
    });
  }
  createEventVendor(payload) {
    const variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateEventVendorInput!) {
          createEventVendor(data: $data) {
            id
            status
          }
        }
      `,
      variables,
    });
  }
  createVenueVendor(payload) {
    payload['venue'] = this.userSvc.user.venue.id;
    const variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateVenueVendorInput!) {
          createVenueVendor(data: $data) {
            id
            vendor {
              id
              name
              office
              address
              website
            }

            venue {
              id
              name
            }
          }
        }
      `,
      variables,
    });
  }
  createUser(venueUser: any) {
    const variables = {
      data: {
        ...venueUser,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateVenueUserInput!) {
          createVenueUser(data: $data) {
            id
            username
            firstName
            lastName
            email
            status
            venue {
              id
              name
            }
          }
        }
      `,
      variables,
    });
  }
  venueUser() {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueUser(id:"${this.userSvc.user.id}") {
              id
              username
              firstName
              lastName
              password
              venue{ id }
              status
              email
            }
          }
          `,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  updateVenue(payload, id: string) {
    payload['yearEstablished'] = parseInt(payload['yearEstablished'], 10);
    if (payload['primaryCategory'] == null || !payload['primaryCategory']) {
      delete payload['primaryCategory'];
    }
    if (payload['secondaryCatgory'] == null || !payload['secondaryCatgory']) {
      delete payload['secondaryCatgory'];
    }

    const variables = {
      id,
      data: {
        ...payload,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: updateVenueInput) {
          updateVenue(id: $id, data: $data) {
            id
            name
            venqId
            yearEstablished
            status
            description
            logo
            address
            addressState
            addressCity
            addressZip
            addressLat
            addressLng
          }
        }
      `,
      variables,
    });
  }
  deleteEventVendor(id: string) {
    const variables = {
      id,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String!) {
          deleteEventVendor(id: $id) {
            id
            status
          }
        }
      `,
      variables,
    });
  }
  deleteTimelineItem(id: string) {
    const variables = {
      id,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteTimelineItem(id: $id) {
            id
            description
          }
        }
      `,
      variables,
    });
  }
  updateUser(id: string, payload) {
    payload['id'] = id;
    delete payload['id'];
    delete payload['__typename'];
    const variables = {
      id,
      data: {
        ...payload,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: updateVenueUserInput) {
          updateVenueUser(id: $id, data: $data) {
            id
            username
            firstName
            lastName
            email
            status
            role
          }
        }
      `,
      variables,
    });
  }

  updateVenueUserPassword(oldpass, newpass) {
    const variables = {
      oldpassword: oldpass,
      newpassword: newpass,
      userId: this.userSvc.user.id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation (
          $oldpassword: String!
          $newpassword: String!
          $userId: String!
        ) {
          updateVenueUserPassword(
            newpassword: $newpassword
            oldpassword: $oldpassword
            userId: $userId
          ) {
            status
            message
          }
        }
      `,
      variables,
    });
  }

  uploadProfilePicture(id, payload) {
    const variables = {
      id,
      data: {
        ...payload,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: uploadImageInput!) {
          uploadImage(id: $id, data: $data) {
            status
            url
            id
          }
        }
      `,
      variables,
    });
  }
  uploadImage(eventId: string, payload) {
    const variables = {
      id: eventId,
      data: {
        ...payload,
      },
    };
    // return this.apollo.mutate({
    //   mutation: gql`
    //     mutation($id:String,$data:uploadImageInput!) {
    //       uploadImage(id:$id,data: $data ){
    //         status
    //         url
    //         id
    //       }
    //   }`,
    //   variables
    // })

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: uploadImageInput!) {
          createEventPhoto(id: $id, data: $data) {
            floorPlans {
              id
              url
              
              
            }
          }
        }
      `,
      variables,
    });
  }

  deleteEventPhoto({ photoId, eventId }) {
    const variables = { photoId, eventId };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($photoId: String, $eventId: String) {
          deleteEventPhoto(photoId: $photoId, eventId: $eventId) {
            floorPlans {
              id
              url
            }
          }
        }
      `,
      variables,
    });
  }

  updateEvent(id: string, payload) {
    payload['id'] = id;

    const variables = {
      id,
      data: {
        ...payload,
        venue: this.userSvc.user.venue.id,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: updateEventInput) {
          updateEvent(id: $id, data: $data) {
            id
            partyName
            onDate
            eventSpace {
              id
              name
            }
            eventType
            guestCount
            floorPlan
          }
        }
      `,
      variables,
    });
  }

  linkEventCustomer(payload) {
    const variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: linkEventCustomerInput) {
          linkEventCustomer(data: $data) {
            id
            partyName
            customers {
              id
              user {
                id
                name
                email
              }
            }
          }
        }
      `,
      variables,
    });
  }
  deleteVenueUser(id) {
    const variables = {
      id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteVenueUser(id: $id) {
            id
            username
            firstName
          }
        }
      `,
      variables,
    });
  }
  deleteVenueSpace(id: string) {
    const variables = {
      id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteVenueSpace(id: $id) {
            id
            name
          }
        }
      `,
      variables,
    });
  }
  deleteEvent(id: string) {
    const variables = {
      id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteEvent(id: $id) {
            id
            floorPlan
          }
        }
      `,
      variables,
    });
  }

  public customers() {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueCustomers(id:"${this.userSvc.user.venue.id}") {
              id
              name
              phone
              email
            }
          }
         `,
    }).valueChanges;
  }

  public customer(id: string) {
    return this.apollo.watchQuery({
      query: gql`
           {
            venueCustomer(id:"${id}") {
              id
              name
              phone
              email
            }
          }
         `,
    }).valueChanges;
  }
  deleteVenueVendor(id) {
    const variables = {
      id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteVenueVendor(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }
  public searchVendor(venqId) {
    const variables = {
      id: venqId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String) {
          vendorVenqId(id: $id) {
            id
            name
            address
            category {
              id
              name
            }
          }
        }
      `,
      variables,
    }).valueChanges;
  }
  sendConfirmationVendors(vendorIds) {
    const variables = {
      ids: vendorIds,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($ids: [String]) {
          sendConfirmationVendors(ids: $ids) {
            status
            # venqId
            # vendorEmail
            # vendorName
          }
        }
      `,
      variables,
    });
  }

  inviteVendor(payload) {
    const variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: inviteInput!) {
          sendVendorInvite(invite: $data) {
            status
            # venqId
            # vendorEmail
            # vendorName
          }
        }
      `,
      variables,
    });
  }
}
