<h2 mat-dialog-title>{{ data.id ? 'Edit' : 'Add' }} Timeline Item</h2>
<form #timelineForm="ngForm">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>When *</mat-label>
          <input matInput   [(ngModel)]="data.onTime" type="datetime-local" name="onTime">
       
        </mat-form-field> 
       

        <mat-form-field appearance="outline">
          <mat-label>What</mat-label>
          <input
            placeholder="Name"
            [(ngModel)]="data.item"
            name="item"
            #timelineitem="ngModel"
            required
            matInput
          />
          <mat-error *ngIf="timelineitem.invalid">Please Enter Name</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>Where</mat-label>
          <!-- still had to add to the mutation-->
          <input
            placeholder="location"
            [(ngModel)]="data.where"
            #timelineitem="ngModel"
            name="where"
            matInput
          />
        </mat-form-field>
        <!-- still had to add to the mutation-->

        <!-- <mat-form-field appearance="outline"> -->
        <mat-label>Description</mat-label>

        <angular-editor
          [config]="editorConfig"
          name="description"
          [(ngModel)]="data.description"
        ></angular-editor>
        <!-- <input 
          placeholder="Description" 
          [(ngModel)]="data.description"
          name="description"
          #description="ngModel"
          matInput > -->
        <!-- </mat-form-field> -->
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>Access</mat-label>
          <mat-select
            [(ngModel)]="data.access"
            name="access"
            #access="ngModel"
            required
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="client">Client Only</mat-option>
            <mat-option value="vendor">Vendor Only</mat-option>
            <mat-option value="client/vendor">Client/Vendor Only</mat-option>
            <mat-option value="venue">Venue Only</mat-option>
          </mat-select>
          <mat-error *ngIf="access.invalid">Please Select Access</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="btn mat-raised-button bg-secondary"
      [mat-dialog-close]="close"
    >
      Cancel
    </button>
    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onSubmitTimeLine(timelineForm, false)"
      *ngIf="!data.id"
      cdkFocusInitial
    >
      Create
    </button>

    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onSubmitTimeLine(timelineForm, true)"
      *ngIf="!data.id"
      cdkFocusInitial
    >
      Next Item
    </button>

    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onupdateTimeLine(timelineForm)"
      *ngIf="data.id"
      cdkFocusInitial
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
