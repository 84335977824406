import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-spaces-detail',
  templateUrl: './spaces-detail.component.html',
  styleUrls: ['./spaces-detail.component.scss'],
})
export class SpacesDetailComponent implements OnInit {
  venueSpace: any = {};
  venueId: any;
  isCreate = true;
  image: any;

  constructor(
    private userSvc: UserService,
    private venueSvc: VenueService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.venueId = this.userSvc.user.venue.id;
    this.venueSpace.venue = { connect: { id: this.venueId } };
    if (this.route.snapshot.params.id) {
      this.isCreate = false;
      this.getEvent(this.route.snapshot.params.id);
    }
  }

  getEvent(id: string) {
    this.venueSvc.space(id).subscribe((res) => {
      this.venueSpace = res['data']['venueSpace'];
    });
  }

  onSubmit(eventForm: NgForm) {
    if (!eventForm.invalid) {
      const controls = eventForm.controls;
      const payload = {
        name: controls.venueSpaceName.value,
        sqft: controls.venueSpacesqft.value,
        venue: this.venueId,
        type: controls.venueSpacetype.value,
        maxGuests: controls['venueSpacemaxGuests'].value || 0,
      };
      this.venueSvc.createSpace(payload).subscribe((res) => {
        this.venueSvc.showSuccess('Event Space Added Successfully');
        this.router.navigate(['spaces']);
      });
    }
  }

  Update(eventForm: NgForm) {
    if (!eventForm.invalid) {
      const controls = eventForm.controls;
      const payload = {
        name: controls.venueSpaceName.value,
        sqft: controls.venueSpacesqft.value,
        // venue: this.venueId,
        type: controls.venueSpacetype.value,
        maxGuests: controls['venueSpacemaxGuests'].value || 0,
      };

      this.venueSvc
        .updateSpace(this.venueSpace.id, payload)
        .subscribe((res) => {
          this.venueSvc.showSuccess('Event Space Updated Successfully');
          this.router.navigate(['spaces']);
        });
    }
  }
}
