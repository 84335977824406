import { Component, OnInit, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss'],
})
export class UsersDetailComponent implements OnInit {
  venueUserDetail: any = {};
  isCreate = true;
  constructor(
    private userService: UserService,
    private router: Router,
    private venueSvc: VenueService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.route.snapshot.params.id) {
      this.isCreate = false;
      this.getUser(this.route.snapshot.params.id);
    }
  }
  getUser(id: string) {
    this.venueSvc.user(id).subscribe((res) => {
      this.venueUserDetail = res['data']['venueUser'];
    });
  }

  onSubmit(eventForm: NgForm) {
    if (eventForm.valid) {
      const controls = eventForm.controls;
      const payload = {
        username: controls.username.value,
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        email: controls.email.value,
        password: controls.password.value,
        role: controls.role.value,
        status: controls.status.value,
        venue: this.userService.user.venue.id,
      };

      this.venueSvc.createUser(payload).subscribe((res) => {
        // localStorage.setItem("user", JSON.stringify(res['data']['createUser']));
        this.venueUserDetail = {};
        this.venueSvc.showSuccess('User Added Successfully');
        this.router.navigate(['users']);
      });
    }
  }

  Update(eventForm: NgForm) {
    if (eventForm.valid) {
      const controls = eventForm.controls;
      const payload = {
        username: controls.username.value,
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        email: controls.email.value,
        // password: controls.password.value,
        role: controls.role.value,
        status: controls.status.value,
        venue: this.userService.user.venue.id,
      };

      this.venueSvc
        .updateUser(this.route.snapshot.params.id, payload)
        .subscribe((res) => {
          //  localStorage.setItem("userUpdate", JSON.stringify(res['data']['updateVenueUser']));
          this.venueUserDetail = {};
          this.venueSvc.showSuccess('User Updated Successfully');
          this.router.navigate(['users']);
        });
    }
  }
  cancel() {
    this.router.navigate(['/users']);
  }
}
