<!--<mat-toolbar>
 <div class="input-group">
    <input type="text" class="form-control" placeholder="Search this space">
    <div class="input-group-append">
      <button class="btn btn-secondary" type="button">
        <i class="material-icons">search</i>
      </button>
    </div>
  </div> 

</mat-toolbar>-->

<div class="p-3">
  <div class="text-right">
    <a routerLink='/vendors-detail/new'><i class="material-icons">add_circle</i>
      <p class="start">Add Vendor</p>
    </a> &nbsp;&nbsp;
    <a (click)="inviteVendor()"><i class="material-icons">add_circle</i>
      <p class="start">Invite Vendor</p>
    </a>
  </div>
  <table class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr class="vq-header">
        <th mat-sort-header="vendorName">Company Name</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Website</th>
        <th>Action</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let vendor of vendors" class="vq-rows">
        <td><a></a>{{ vendor?.vendor?.name }}</td>
        <td>{{ vendor?.vendor?.office }}</td>
        <td classs="text">{{ vendor?.vendor?.address }}</td>
        <td classs="text">{{ vendor?.vendor?.website }}</td>
        <td>
          <span (click)="deleteVendor(vendor.id)" class="delete">
            Delete <i class="material-icons">
              backspace
            </i>
          </span>
        </td>
      </tr>
    </tbody>

  </table>
</div>