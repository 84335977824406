import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showEmail = true;
  userId: any = localStorage.getItem('userIdPass');
  password: any = '';
  confirmpassword: any = '';
  constructor(private venueSvc: VenueService) {}

  ngOnInit() {}
  onSubmit(form: NgForm) {
    if (form.valid) {
      const controls = form.controls;

      const payload = {
        password: controls.password.value,
        userType: 'venueUser',
        id: this.userId,
      };
      this.venueSvc.updatePassword(payload).subscribe((res) => {
        this.venueSvc.showSuccess('password Updated Successfully');
      });
    }
  }
}
