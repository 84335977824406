import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  error = false;
  IsChecked = true;

  login: any = {};
  isLogin: boolean;
  constructor(
    private authService: AuthService,
    private userSVc: UserService,
    // public apollo:Apollo,
    private router: Router,
    private venueSvc: VenueService
  ) {}

  ngOnInit() {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      const controls = form.controls;

      const payload = {
        venue: controls.venqid.value,
        username: controls.username.value,
        password: controls.password.value,
      };

      this.authService.signIn(payload).subscribe(
        (res) => {
          this.error = false;
          if (res['data']['venueUserLogin']['status'] === 'active') {
            localStorage.setItem(
              'logindata',
              JSON.stringify(res['data']['venueUserLogin'])
            );
            localStorage.setItem(
              'token',
              res['data']['venueUserLogin']['token']
            );
            localStorage.setItem(
              'role',
              res['data']['venueUserLogin']['user']['role']
            );
            this.authService.token = res['data']['venueUserLogin']['token'];
            this.authService.isLoggedin = true;
            this.userSVc.user = res['data']['venueUserLogin']['user'];
            //     this.userSVc.user.role = res["data"]["venueUserLogin"]["user"]["role"]

            this.venueSvc.showSuccess('Logged In Successfuly!');
            // if(this.IsChecked)
            //   localStorage.setItem('keep','1')
            // else
            // localStorage.setItem('keep','0')
            this.router.navigate(['events']);
          }
        }
        // (error) => {
        //   this.error = true;
        // }
      );
    }
  }

  check() {
    this.IsChecked = !this.IsChecked;
  }

  checked() {
    // tslint:disable-next-line: no-conditional-assignment
    if ((this.IsChecked = false)) {
      setTimeout(() => {
        localStorage.removeItem('logindata');
        localStorage.removeItem('token');
        this.authService.isLoggedin = false;
        this.router.navigate(['sign-in']);
      }, 3000);
    }
  }
}
