import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requests-detail',
  templateUrl: './requests-detail.component.html',
  styleUrls: ['./requests-detail.component.scss'],
})
export class RequestsDetailComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
