import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { VenueService } from 'app/services/venue.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  venueUser: any = JSON.parse(localStorage.getItem('logindata')).user;
  role: any = '';
  venueId: any = '';
  primaryCategoryId: any = '';
  secondaryCategoryId: any = '';
  venue: any = {
    primaryCategory: {
      id: '',
    },
    secondaryCategry: {
      id: '',
    },
  };
  file: any;
  oldpassword: any = '';
  newpassword: any = '';
  confirmnewpassword: any = '';
  categories: any = [];

  constructor(private venueSvc: VenueService, private route: Router) {}

  ngOnInit() {
    this.venueId = this.venueUser.venue.id;
    this.role = this.venueUser.role;
    // console.log(this.role)
    this.getVenueInfo();
    this.venueCategories();
  }
  venueCategories() {
    this.venueSvc.categories().subscribe((res) => {
      this.categories = res['data']['venueCategories'];
      console.log('categories===>', this.categories);
      // console.log(this.categories)
    });
  }
  getVenueInfo() {
    this.venueSvc.venue(this.venueId).subscribe((res) => {
      this.venue = res['data']['venue'];
      console.log('venue===>', this.venue);

      this.primaryCategoryId =
        this.venue.primaryCategory != null ? this.venue.primaryCategory.id : '';
      this.secondaryCategoryId =
        this.venue.secondaryCatgory != null
          ? this.venue.secondaryCatgory.id
          : '';

      this.venue.logo = this.venue.logo
        ? this.venue.logo
        : '../assets/sponsor-your-300x150.png';
    });
  }
  onFileInput(event) {
    if (event.target.files[0]) {
      const reader = new FileReader();
      this.file = event.target.files[0];
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(event.target.files[0]);
    }
  }
  handleReaderLoaded(e) {
    const payload = {
      id: this.venueId,
      objectType: 'logo',
      objectId: this.venueId,
      imageEncoded: 'data:image/png;base64,' + btoa(e.target.result),
      imageName: this.file.name.split('.').slice(0, -1).join('.'),
    };
    this.venueSvc
      .uploadProfilePicture(this.venueId, payload)
      .subscribe((res) => {
        this.venue.logo = res['data']['uploadImage']['url'];
      });
  }

  Update(form: NgForm) {
    if (form.valid) {
      form.value.logo = this.venue.logo;
      form.value.primaryCategory = this.primaryCategoryId;
      form.value.secondaryCatgory = this.secondaryCategoryId;

      form.value.status = this.venue.status;
      form.value.venqId = this.venue.venqId;

      this.venueSvc.updateVenue(form.value, this.venueId).subscribe((res) => {
        this.venueSvc.showSuccess('Profile Updated Successfully');
        this.route.navigate(['/events']);
      });
    }
  }
  UpdatePassword(form: NgForm) {
    if (form.valid && this.newpassword === this.confirmnewpassword) {
      this.venueSvc
        .updateVenueUserPassword(this.oldpassword, this.newpassword)
        .subscribe(
          (res) => {
            if (
              res['data']['updateVenueUserPassword']['status'] === 'success'
            ) {
              this.venueSvc.showSuccess(
                res['data']['updateVenueUserPassword']['message']
              );
            } else {
              this.venueSvc.showFailure(
                res['data']['updateVenueUserPassword']['message']
              );
            }

            this.route.navigate(['/events']);

            //   this.venueSvc.showFailure('Old Password does not match')
          }
          // (error) => {
          //   this.venueSvc.showFailure(
          //     "There was some error updating the password"
          //   );
          // }
        );
    }
  }
}
