import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VenueService } from '../../services/venue.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from 'app/components/delete/delete.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  public events: any;
  eventId: any = '';
  searchParam: any = '';

  constructor(
    private router: Router,
    public venueSvc: VenueService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // console.log('here')
    this.getEvents();
  }

  deleteEvent(eventID) {
    this.eventId = eventID;
    this.openDialog();
    // this.getEvents();
  }
  openDialog() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { eventID: this.eventId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteEvent(this.eventId).subscribe(
          (res) => {
            this.venueSvc.showSuccess('Event Deleted Successfully');
            this.getEvents();
          }
          // (error) => {
          //   this.venueSvc.showWarning('Something Went Wrong');
          // }
        );
      }
    });
    //  this.getEvents();
  }

  getEvents() {
    this.venueSvc.events().subscribe((res) => {
     var events:any[] = [...res['data']['venueEvents']];
      //Sort By Date
      events.sort((a, b) => a.onDate - b.onDate)
     
      this.events = events
    });
  }
  searchEvent() {
    if (!this.searchParam) {
      this.getEvents();
    } else {
      this.events = this.events.filter((x) => {
        // flag = String(x.phoneNo)

        return x.partyName
          .trim()
          .toLowerCase()
          .includes(this.searchParam.trim().toLowerCase());
      });
    }
  }
  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  sortData(e) {
    if (e.direction === 'asc') {
      if (e.active === 'eventSpace') {
        this.events.sort((a, b) =>
          a.eventSpace.name.localeCompare(b.eventSpace.name)
        );
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.events.sort((a, b) => {
          return a[e.active] - b[e.active];
        });
      } else {
        this.events.sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'eventSpace') {
        this.events.sort((a, b) =>
          b.eventSpace.name.localeCompare(a.eventSpace.name)
        );
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.events.sort((a, b) => {
          return b[e.active] - a[e.active];
        });
      } else {
        this.events.sort(this.dynamicSort('-' + e.active));
      }
    }
  }
}
