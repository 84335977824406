<div class="example-container">
  <h2 mat-dialog-title>Add Vendor to Event</h2>

  <form class="text-center border border-light p-5" #eventForm="ngForm">
    <input type="hidden" name="key" />
    <div class="row">
      <div class="col-md-5">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>venQID</mat-label>
            <input
              class="uppercase"
              [(ngModel)]="venqID"
              matInput
              name="venQID"
              placeholder="VenQID"
              required
            />
          </mat-form-field>
          <div style="color: red" *ngIf="!found">No Vendor Matched</div>
        </div>
      </div>
      <div class="col-md-2" style="padding-top: 21px">OR</div>
      <div class="col-md-5">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Select Vendor</mat-label>

            <mat-select
              (selectionChange)="onVendorChange($event)"
              [(ngModel)]="venqID"
              name="venqId"
              #venqId="ngModel"
            >
              <mat-option
                *ngFor="let venueVendor of venueVendors"
                [value]="venueVendor.vendor.venqId"
                >{{ venueVendor.vendor.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-md-6 text-left">
        <button
          type="button"
          class="btn mat-raised-button mat-primary"
          (click)="searchVendor()"
        >
          Search Vendor
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Name..."
              disabled="true"
              name="vendor"
              [(ngModel)]="vendorDetail.name"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input
              matInput
              disabled="true"
              placeholder="Category"
              name="category"
              [(ngModel)]="vendorDetail.category"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Address</mat-label>
            <input
              matInput
              disabled="true"
              placeholder="Address"
              name="address"
              [(ngModel)]="vendorDetail.address"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="submit">
      <button
        mat-button
        class="btn mat-raised-button bg-secondary mr-2"
        [mat-dialog-close]="close"
      >
        Cancel
      </button>
      <button
        type="submit"
        [disabled]="!vendorId"
        class="btn mat-raised-button bg-primary"
        (click)="onSubmit(eventForm)"
      >
        Add Vendor
      </button>
    </div>
  </form>
</div>
