<div class="example-container">
	<form class="text-center border border-light p-5" #eventForm="ngForm">
  
	  <!-- <input type="hidden" name="key" [(ngModel)]="eventService.selectedEvent.key" > -->
  
	  <div class="row">
		<div class="col-md-6">
		  <div class="form-group">
			<mat-form-field appearance="outline"> 
					<mat-label>Username</mat-label>
			  <input type="text" style="text-transform:lowercase" matInput placeholder="Username" name="username" #username="ngModel" [(ngModel)]="venueUserDetail.username"
				required>
				<mat-error *ngIf="username.invalid">Please Enter UserName</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline">
					<mat-label>First Name</mat-label>
			  <input type="text" matInput placeholder="First Name"  #firstName="ngModel" name="firstName" [(ngModel)]="venueUserDetail.firstName"
				required>
				<mat-error *ngIf="firstName.invalid">Please Enter First Name</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline">
					<mat-label>Last Name</mat-label>
			  <input type="text" required matInput placeholder="Last Name" #lastName="ngModel" name="lastName" [(ngModel)]="venueUserDetail.lastName"
				>
				<mat-error *ngIf="lastName.invalid">Please Enter Last Name</mat-error>
			</mat-form-field>
  
			<mat-form-field appearance="outline">
			  <mat-label>Email</mat-label>
			  <input type="text" required matInput placeholder="Email" #email="ngModel" name="email" [(ngModel)]="venueUserDetail.email"
			  >
			  <mat-error *ngIf="email.invalid">Please Enter Email</mat-error>
  
			</mat-form-field>
					  
					  <!-- <mat-form-field class="example-full-width">
						  <input matInput [matDatepicker]="dp" placeholder="Choose a date">
						  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
						  <mat-datepicker  #dp></mat-datepicker>
					  </mat-form-field> -->
		  </div>
		</div>
  
		<div class="col-md-6">
		  <div class="form-group">
			<mat-form-field *ngIf="isCreate" appearance="outline">
			  <mat-label>Password</mat-label>
  
  
			
			  <input type="password" required matInput placeholder="PAssword" #password="ngModel" name="password" [(ngModel)]="venueUserDetail.password"
			  >
			  <mat-error *ngIf="password.invalid">Please Enter Password</mat-error>
			</mat-form-field>
  
			
  
  
			<mat-form-field appearance="outline">
					<mat-label>Role</mat-label>
					<mat-select [(ngModel)]="venueUserDetail.role" name="role" #role="ngModel" required>
						<mat-option  value="">Choose Role</mat-option>
						<mat-option  value="admin">Admin</mat-option>
						<mat-option  value="user">User</mat-option>
					  </mat-select>
					  <mat-error *ngIf="role.invalid">Please Select Role</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline">
					<mat-label>Status</mat-label>
					<mat-select #status="ngModel" name="status" [(ngModel)]="venueUserDetail.status" required>
							<mat-option  value="">Choose Status</mat-option>
							<mat-option  value="active">Active</mat-option>
							<mat-option  value="inactive">InActive</mat-option>
						  </mat-select>
				
					<mat-error *ngIf="status.invalid">Please Select Status</mat-error>
			  </mat-form-field>
  
		  </div>
		</div>
	  </div>
  
	  <div>
		<button mat-button  type="button" class="btn mat-raised-button bg-secondary mr-2" (click)="cancel()">Cancel</button>
		<button type="submit" *ngIf="isCreate" class="btn mat-raised-button bg-primary" (click)="onSubmit(eventForm)">Create</button>
		<button type="submit" *ngIf="!isCreate" class="btn mat-raised-button bg-primary" (click)="Update(eventForm)">Update</button>
	  </div>
  
	</form>
  </div>
  
  
  