<mat-toolbar >
    <div class="input-group ">
      <input type="text" class="form-control" [(ngModel)]="searchParam" (keyup)="searchEvent()" placeholder="Search by Event Name">
      <div class="input-group-append">
        <button (click)="searchEvent()" class="btn btn-secondary" type="button">
          <i class="material-icons">search</i> 
        </button>
      </div>
    </div>
 
  </mat-toolbar>
<div class="p-3">
    <div class=" text-right">
          <a routerLink='/event/new'><i class="material-icons">add_circle</i><p class="start">Add Event</p></a>
      </div>
 <table class="mat-elevation-z8"  matSort (matSortChange)="sortData($event)">
    <thead>
      <tr class="vq-header">
        <th mat-sort-header="onDate" >Date</th>
        <th mat-sort-header="partyName">Event Name</th>
        <th mat-sort-header="eventSpace">Space</th>
        <th mat-sort-header="guestCount">Guests</th>
        <th>Action</th>
      </tr>
    </thead>
      
    <tbody>
			<tr class="vq-rows" *ngFor="let event of events">
				<td ><a [routerLink]="['/event/'+event.id]"> {{event.onDate*1000 | date}}</a></td>
				<td>{{event.partyName}}</td>
				<td>{{event?.eventSpace?.name}}</td>
				<td>{{event.guestCount}}</td>
        <td > 
          <span (click)="deleteEvent(event.id)" class="delete">
            Delete <i class="material-icons">
              backspace
              </i>
          </span>
          <span [routerLink]="['/event/'+event.id]" class="update ml-1">
              Update <i class="material-icons">
                  history
                  </i>
            </span>
        </td>
			</tr>
    </tbody>
      
 </table>
</div>

