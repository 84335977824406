import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { VenueService } from 'app/services/venue.service';
import { DeleteComponent } from 'app/components/delete/delete.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: any = [];
  userId: any = '';
  searchParam: any = '';

  constructor(
    public userService: UserService,
    private router: Router,
    private venueSvc: VenueService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getUserList();
  }

  getUserList() {
    this.venueSvc.users().subscribe((users) => {
      this.users = [...users['data']['venue']['venueUsers']];
      this.users = this.users.sort( (a,b) => a.firstName > b.firstName ? 1 : -1)
    });
  }
  deleteUser(userId) {
    this.userId = userId;
    this.openDialog();
    // this.getEvents();
  }
  openDialog() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { eventID: this.userId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteVenueUser(this.userId).subscribe((res) => {
          this.venueSvc.showSuccess('User Deleted Successfully');
          this.getUserList();
        });
      }
    });
    // this.getUserList();
  }
  searchUser() {
    if (!this.searchParam) {
      this.getUserList();
    } else {
      this.users = this.users.filter((x) => {
        // flag = String(x.phoneNo)

        return x.username
          .trim()
          .toLowerCase()
          .includes(this.searchParam.trim().toLowerCase());
      });
    }
  }
  sortData(e) {
    if (e.direction === 'asc') {
      this.users.sort(this.dynamicSort(e.active));
    } else {
      this.users.sort(this.dynamicSort('-' + e.active));
    }
  }
  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  // onEdit(user: User) {
  // 	this.router.navigate(['/user-details']);
  //   this.userService.selectedUser = Object.assign({}, user);

  // }

  // deleteUser(user: User) {
  //   this.userService.deleteUser(user);
  // }
}
