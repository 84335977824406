import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { VenueService } from 'app/services/venue.service';
import { UserService } from 'app/services/user.service';
import { DeleteComponent } from 'app/components/delete/delete.component';
import { InviteComponent } from 'app/vendor/modals/invite/invite.component';

export interface PeriodicElement {
  name: string;
  date: string;
  type: string;
  price: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { date: '12/09/2019', name: 'Flowers', type: 'Florist', price: 230 },
  { date: '16/09/2019', name: 'Table', type: 'Table top decor   ', price: 111 },
  { date: '13/09/2019', name: 'Dj', type: 'Dj Clue', price: 20 },
  { date: '06/09/2019', name: 'Content', type: 'Top content', price: 22 },
];

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent implements OnInit {
  displayedColumns: string[] = ['date', 'type', 'name', 'price'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  vendors: any = [];
  vendorId: any = '';
  constructor(
    private venueSvc: VenueService,
    public dialog: MatDialog,
    private userSvc: UserService
  ) {}

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.vendorList();
  }

  vendorList() {
    this.venueSvc.venueVendors().subscribe((res) => {
      this.vendors = [...res['data']['venue']['vendorBook']];
      this.sortData({ active: 'vendorName', direction: 'asc' });
    });
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  sortData(e) {
    if (e.direction === 'asc') {
      if (e.active === 'vendorName') {
        this.vendors.sort((a, b) => a.vendor.name.localeCompare(b.vendor.name));
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.vendors.sort((a, b) => {
          return a[e.active] - b[e.active];
        });
      } else {
        this.vendors.sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'vendorName') {
        this.vendors.sort((a, b) => b.vendor.name.localeCompare(a.vendor.name));
      } else if (e.active === 'guestCount' || e.active === 'onDate') {
        this.vendors.sort((a, b) => {
          return b[e.active] - a[e.active];
        });
      } else {
        this.vendors.sort(this.dynamicSort('-' + e.active));
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { vendorId: this.vendorId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteVenueVendor(this.vendorId).subscribe((res) => {
          this.vendorList();
          this.venueSvc.showSuccess('Vendor Removed Successfully');
        });
      }
    });
  }

  deleteVendor(vendorId) {
    this.vendorId = vendorId;
    this.openDialog();
  }

  inviteVendor() {
    const dialogRef = this.dialog.open(InviteComponent, {
      width: '600px',
      // data: { vendorId: this.vendorId }
    });
    dialogRef.afterClosed().subscribe((inviteData: NgForm) => {
      console.log(inviteData);
      if (inviteData.valid) {
        const controls = inviteData.controls;
        const data = {
          // venqId: controls.venqId.value,
          date: new Date().getTime() / 1000,
          vendorEmail: controls.vendorEmail.value,
          vendorName: controls.vendorName.value,
          venueName: this.userSvc.user.venue.name,
        };
        this.venueSvc.inviteVendor(data).subscribe((res) => {
          this.venueSvc.showSuccess('Invite Send Successfully');
        });
      } else {
        this.venueSvc.showFailure('Invalid details');
      }
    });
  }
}
