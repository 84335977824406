<mat-toolbar>
  <div class="input-group">
    <input type="text" class="form-control" [(ngModel)]="searchParam" (keyup)="searchEventSpace()" placeholder="Search by Space name">
    <div class="input-group-append">
      <button (click)="searchEventSpace()" class="btn btn-secondary" type="button">
        <i class="material-icons">search</i>
      </button>
    </div>
  </div>

</mat-toolbar>
<div class="p-3">
<div class="text-right">
      <a routerLink='/space/new'><i class="material-icons">add_circle</i><p class="start">Add Space</p></a>
  </div>
  <table class="mat-elevation-z8"  matSort (matSortChange)="sortData($event)">
    <thead>
      <tr class="vq-header">
        <th mat-sort-header="name">Name</th>
        <th mat-sort-header="sqft">SqFt</th>
        <th mat-sort-header="type">Type</th>
        <th mat-sort-header="maxGuests">Max Guests</th>
        <th>Action</th>
      </tr>
    </thead>
      
    <tbody>
			<tr class="vq-rows" *ngFor="let space of spaces">
				<td><a [routerLink]="['/space/'+space.id]"> {{ space.name }}</a></td>
				<td>{{space.sqft}}</td>
				<td>{{space.type}}</td>
        <td>{{space.maxGuests}}</td>
        <td > 
          <span (click)="deleteEventSpace(space.id)" class="delete">
            Delete <i class="material-icons">
              backspace
              </i>
          </span>
          <span [routerLink]="['/space/'+space.id]" class="update ml-1">
            Update <i class="material-icons">
                history
                </i>
          </span>
          </td>
			</tr>
    </tbody>
      
    </table>

  </div>
