import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectEventTypeComponent } from './select-event-type/select-event-type.component';
import { TimelineaddmodalComponent } from './timelineaddmodal/timelineaddmodal.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DeleteComponent } from './delete/delete.component';
import { CustomerComponent } from './customer/customer.component';
import { ImagepreviewComponent } from './imagepreview/imagepreview.component';
import { AddEventVendorComponent } from './add-event-vendor/add-event-vendor.component';
import { EventNoteComponent } from './event-note/event-note.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PrintViewTimelineComponent } from './print-view-timeline/print-view-timeline.component';
// import { ImageViewerModule } from 'ng2-image-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { DateplustimePickerComponent } from './dateplustime-picker/dateplustime-picker.component';

const matComponents = [
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatDatepickerModule,
  MatSelectModule,
  MatSidenavModule,
  MatButtonModule,
  MatTabsModule,
  MatNativeDateModule,
];
@NgModule({
  declarations: [
    SelectEventTypeComponent,
    TimelineaddmodalComponent,
    DeleteComponent,
    CustomerComponent,
    ImagepreviewComponent,
    AddEventVendorComponent,
    EventNoteComponent,
    SpinnerComponent,
    PrintViewTimelineComponent,
    DateplustimePickerComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //    ImageViewerModule,
    AngularEditorModule,
    ...matComponents,
  ],
  exports: [
    SelectEventTypeComponent,
    TimelineaddmodalComponent,
    DeleteComponent,
    SpinnerComponent,
    PrintViewTimelineComponent,
    ...matComponents,
  ],
})
export class ComponentsModule {}
