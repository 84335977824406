<div class="example-container">
	<h1 class="modal-header">Invite Vendor</h1>
	<form class="text-center border border-light p-5" #inviteForm="ngForm">
		<div class="row">
			<!-- <div class="col-md-12">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>venQID</mat-label>
						<input class="uppercase" #venqId="ngModel" [(ngModel)]="inviteData.venqId" matInput
							name="venqId" placeholder="VenQID" required>
					</mat-form-field>
				</div>
			</div> -->

			<div class="col-md-12">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>Name</mat-label>
						<input matInput placeholder="Name" #vendorName="ngModel" name="vendorName"
							[(ngModel)]="inviteData.vendorName">
					</mat-form-field>
				</div>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>Email</mat-label>
						<input type="text" matInput placeholder="Email" #vendorEmail="ngModel" name="vendorEmail"
							[(ngModel)]="inviteData.vendorEmail" required email>
						<!-- <mat-error *ngIf="vendorEmail.invalid">Please Enter Email</mat-error> -->
						<mat-error
							*ngIf="(vendorEmail.touched || inviteForm.submitted) && vendorEmail.errors?.required">Please
							Enter Email</mat-error>
						<mat-error *ngIf="vendorEmail.touched && vendorEmail.errors?.email">Enter a valid email address
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="submit">
				<button type="submit" mat-button  [disabled]="!inviteData.vendorEmail" class="btn mat-raised-button bg-primary"
				>Invite</button>

			<!-- <button type="button"
				class="btn mat-raised-button bg-primary" [mat-dialog-close]="inviteForm">Invite</button> -->
		</div>
	</form>
</div>