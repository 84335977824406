import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(public apollo: Apollo) {}

  listСustomer() {
    return this.apollo.watchQuery({
      query: gql`
        {
          venueCustomers {
            id
            customer {
              id
            }
            id
            name
            phone
            email
          }
        }
      `,
    }).valueChanges;
  }
  deleteCustomer(id) {
    const variables = {
      id,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String!) {
          deleteUser(id: $id) {
            id
            name
          }
        }
      `,
      variables,
    });
  }
}
