<mat-toolbar>
  <div class="input-group" [formGroup]="searchForm">
    <input
      type="text"
      placeholder="Select Date"
      ngModel
      name="datebook"
      bsDatepicker
      #dp="bsDatepicker"
      formControlName="datebook"
      [minDate]="minDateValue"
      [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY' }"
      [(bsValue)]="valueDate"
      class="form-control"
      placeholder="Select Date"
    />
    <input
      class="form-control"
      type="text"
      name="maxGuests"
      [(ngModel)]="maxGuests"
      formControlName="maxGuests"
      min="0"
      max="99999999999"
      placeholder="Enter Max Guests..."
    />
    <!-- <div
      *ngIf="guests.invalid && (guests.dirty || guests.touched)"
      class="alert alert-danger"
    >
      <div *ngIf="guests.errors?.pattern">
        Max guests must be number.
      </div>
    </div> -->
    <!-- <button (click)="searchVenue()"
      style="height: 38px"
       class="btn btn-secondary" type="button">
        <i class="material-icons">search</i>
      </button> -->
    <div class="input-group-append">
      <button class="btn btn-secondary" type="button" (click)="searchVenue()">
        <i class="material-icons">search</i>
      </button>
    </div>
  </div>
</mat-toolbar>

<div class="p-3">
  <table *ngIf="venues.length > 0" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr class="vq-header">
        <th mat-sort-header="name">Name</th>
        <th mat-sort-header="calories">Address</th>
        <th mat-sort-header="fat">Year Established</th>
        <!-- <th mat-sort-header="carbs">Guests</th> -->
      </tr>
    </thead>

    <tbody>
      <tr class="vq-rows" *ngFor="let venue of venues">
        <td>
          <a [routerLink]="['/venue/' + venue.id]"> {{ venue.name }}</a>
        </td>
        <td>{{ venue.address }}</td>
        <td>{{ venue.yearEstablished }}</td>
        <!-- <td>{{venue.guestCount}}</td> -->
      </tr>
    </tbody>
  </table>

  <div *ngIf="venues.length === 0 && showMessage">No Venues found</div>
</div>
