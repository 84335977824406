import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-event-type',
  templateUrl: './select-event-type.component.html',
  styleUrls: ['./select-event-type.component.scss'],
})
export class SelectEventTypeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
