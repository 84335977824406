<div class="example-container">

    <mat-tab-group class="p-5">
        <mat-tab label="Profile"> <form class="text-center border border-light p-5" #venueForm="ngForm">

            <!-- <input type="hidden" name="key" [(ngModel)]="eventService.selectedEvent.key" > -->
        
            <div class="row ">
              <div class="col-md-4">
                <div class="form-group">
                  <div class="square" >
                    <img   
                    [src]="venue.logo ? venue.logo : '../assets/sponsor-your-300x150.png'"  
                    class="img-fluid" alt="">
                  </div> 
        
                  <button mat-button class="btn mat-raised-button bg-secondary mr-2" (click)="fileInput.click()">
                    <i  class="large material-icons">library_add</i>
                    <span>Select</span>
                    <input #fileInput type="file" (change)="onFileInput($event)" accept="image/png, image/jpg" style="display:none;" />
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                  <mat-form-field appearance="outline">
                      <mat-label>Venue Name</mat-label>
                      <input type="text" matInput placeholder="Venue Name" name="name" [(ngModel)]="venue.name"
                        required>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                      <mat-label>Address</mat-label>
                      <input 
                        type="text" 
                        matInput placeholder="Address"
                        name="address" [(ngModel)]="venue.address"
                        required>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>Primary Category</mat-label>
                      <mat-select [(ngModel)]="primaryCategoryId" name="primaryCategory" #primaryCategory="ngModel"
                      >
                      <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.name }}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Office Phone</mat-label>
                    <input 
                    type="text" 
                    matInput
                      placeholder="phone" 
                      name="office" 
                      [(ngModel)]="venue.office">
                  </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Year Established</mat-label>
                      <input 
                      type="text" 
                      matInput 
                      placeholder="Year Established" 
                      name="yearEstablished" 
                      [(ngModel)]="venue.yearEstablished">
                    </mat-form-field>
              </div>
            </div>

            

              <div class="col-md-6">
                  <div class="form-group">
                      <mat-form-field appearance="outline">
                          <mat-label>Label Coordinator</mat-label>
                          <input 
                          type="text" 
                          matInput 
                          placeholder="Coordinator" 
                          name="lblCoordinator" 
                          [(ngModel)]="venue.lblCoordinator">
                      </mat-form-field>
                  <mat-form-field appearance="outline">
                      <mat-label>State</mat-label>
                      <input 
                      type="text" 
                      matInput 
                      placeholder="State" 
                      name="addressState" 
                      [(ngModel)]="venue.addressState">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                      <mat-label>City</mat-label>
                      <input
                       type="text" 
                       matInput 
                       placeholder="City" 
                       name="addressCity" 
                       [(ngModel)]="venue.addressCity">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>Secondary Category</mat-label>
                      <mat-select [(ngModel)]="secondaryCategoryId" name="secondaryCatgory" #secondaryCatgory="ngModel"
                      >
                      <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Instagram</mat-label>
                    <input 
                      type="text" 
                      matInput 
                      placeholder="instagram" 
                      name="instagram" 
                      [(ngModel)]="venue.instagram">
                  </mat-form-field>

               

                  <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea 
                      type="text" 
                      matInput 
                      placeholder="Description" 
                      name="description" 
                      [(ngModel)]="venue.description"></textarea>
                  </mat-form-field>
                 

                  
                
                 
                   
                      </div>
              </div>

              
            </div> 
        
            <div>
              <button [routerLink]="['/events']" mat-button class="btn mat-raised-button bg-secondary mr-2" >Cancel</button>
        
              <button type="submit"  class="btn mat-raised-button bg-primary" (click)="Update(venueForm)">Update</button>
            </div>
        
          </form> </mat-tab>
        <mat-tab *ngIf="role!='admin'" label="Update Password">
          <form #venueFormPassword="ngForm" class="text-center border border-light p-5">
              <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Old Password</mat-label>
                            <input type="password" matInput placeholder="old password" name="oldpassword" #oldpass="ngModel"  [(ngModel)]="oldpassword"
                              required>
                              <mat-error *ngIf="oldpass.invalid">Please Enter Old Password</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                          <mat-form-field appearance="outline">
                              <mat-label>New Password</mat-label>
                              <input type="password" matInput placeholder="new password" #newpass="ngModel" name="newpassword" [(ngModel)]="newpassword"
                                required>
                                <mat-error *ngIf="newpass.invalid">Please Enter New Password</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Confirm New Password</mat-label>
                                <input type="password" matInput placeholder="new password" #confirmnewpass="ngModel" name="confirmnewpassword" [(ngModel)]="confirmnewpassword"
                                  required>
                                  <mat-error *ngIf="confirmnewpass.invalid">Please Enter Password</mat-error>
                                  <mat-error *ngIf="newpassword!=confirmnewpassword">New password does not match!!</mat-error>
                            </mat-form-field>
                          </div>
                  </div>
              </div>    
            

          <div>
               
          
                <button type="submit"  class="btn mat-raised-button bg-primary" (click)="UpdatePassword(venueFormPassword)">Update</button>
              </div>
          
            </form>
      
        </mat-tab>
   
      </mat-tab-group>
  
</div>





