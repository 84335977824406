<div class="continer">
  <div class="col">
    <div class="col-md-12">
    	<mat-grid-list cols="2">
    		<mat-grid-tile>
    			<mat-card>Next event</mat-card>
    		</mat-grid-tile>
				<mat-grid-tile>
    			<mat-card>Upcoming</mat-card>
    		</mat-grid-tile>
			</mat-grid-list>
			</div>
		</div>
	</div>
