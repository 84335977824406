<h2 mat-dialog-title>Add a Customer</h2>
<form #timelineForm="ngForm">

  <div class="col-md-12">
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input 
        placeholder="Name" 
        [(ngModel)]="data.name"
        name="item"
        #customername="ngModel" 
        required
        matInput >
        <mat-error *ngIf="customername.invalid">Please Enter Name</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input 
          placeholder="Email" 
          [(ngModel)]="data.email"
          name="onTime"
          #email="ngModel"
          required
           matInput >
          <mat-error *ngIf="email.invalid">Please Enter Email</mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button class="btn mat-raised-button bg-secondary mr-2" [mat-dialog-close]="close" >Cancel</button>
    <button mat-button class="btn mat-raised-button bg-primary" (click)="onSubmitCustomer(timelineForm)" *ngIf="timelineForm.valid && data.isCreate"  [mat-dialog-close]="data" cdkFocusInitial>Create</button>
    <button mat-button class="btn mat-raised-button bg-primary" (click)="onSubmitCustomer(timelineForm)"  *ngIf="!timelineForm.valid && data.isCreate"    cdkFocusInitial>Create</button>
    <button mat-button class="btn mat-raised-button bg-primary" (click)="onupdateCustomer(timelineForm)"  *ngIf="timelineForm.valid && !data.isCreate"  [mat-dialog-close]="data"   cdkFocusInitial>Update</button>
    <button mat-button class="btn mat-raised-button bg-primary" (click)="onupdateCustomer(timelineForm)"  *ngIf="!timelineForm.valid && !data.isCreate"    cdkFocusInitial>Update</button>
  </mat-dialog-actions>
</form>

  <!-- <mat-form-field appearance="outline">
      <mat-label>Mobile</mat-label>
    <input 
    placeholder="mobile" 
    type="number"
    [(ngModel)]="data.mobile"
    name="mobile"
    #mobile="ngModel"
    
     matInput >
  
</mat-form-field> -->

<!-- <mat-form-field>
    
    <input 
    placeholder="Role" 
    [(ngModel)]="data.role"
    name="role"
    #email="ngModel"
    
     matInput >
    
</mat-form-field>

<mat-form-field>
    
    <input 
 
    [(ngModel)]="data.primaryContact"
    name="primaryContact"
    #email="ngModel"
    type="checkbox"
     matInput >
    
</mat-form-field> -->
