import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  public customers: any = [];

  constructor(private venueSvc: VenueService) {}

  ngOnInit() {
    this.getVenueCustomers();
  }

  getVenueCustomers() {
    this.venueSvc.customers().subscribe((res) => {
      this.customers = res['data']['venueCustomers'];
    });
  }
  sortData(e) {}
}
