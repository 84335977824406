import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  constructor(public apollo: Apollo) {}
  createVenueSpace(venueSpace: any) {
    const variables = {
      data: {
        ...venueSpace,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: VenueSpaceCreateInput!) {
          createVenueSpace(data: $data) {
            id
            venue {
              id
              name
              venueUsers {
                id
                username
                firstName
                lastName
                email
                password
                venue {
                  id
                  name
                  createdAt
                  updatedAt
                }
              }
            }
            name
            createdAt
            updatedAt
          }
        }
      `,
      variables,
    });
  }
  listSpace() {
    return this.apollo.watchQuery({
      query: gql`
        {
          venueSpaces {
            id
            venue {
              id
            }
            name
            sqft
            type
            maxGuests
          }
        }
      `,
    }).valueChanges;
  }
}
