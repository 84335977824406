import { VenueService } from './../../services/venue.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-searchvenue',
  templateUrl: './searchvenue.component.html',
  styleUrls: ['./searchvenue.component.scss'],
})
export class SearchvenueComponent implements OnInit {
  venues: any = [];
  minDateValue: Date = null;
  valueDate: Date = null;
  showMessage = false;
  maxGuests: number;
  searchForm: FormGroup;

  constructor(private venueSvc: VenueService) {}

  ngOnInit() {
    this.setForm();
    this.getLocation();
  }
  setForm() {
    this.searchForm = new FormGroup({
      datebook: new FormControl(this.valueDate, [Validators.required]),
      maxGuests: new FormControl(this.maxGuests, [
        // Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      ]),
    });
  }

  get datebook() {
    return this.searchForm.get('datebook');
  }

  get guests() {
    return this.searchForm.get('maxGuests');
  }

  get searchFormControl() {
    return this.searchForm.controls;
  }

  sortData(e) {}
  getLocation() {
    this.venueSvc.getLocation().then((res) => {
      localStorage.setItem('lat', res.lat);
      localStorage.setItem('lng', res.lng);
    });
  }
  searchVenue() {
    console.log(this.searchForm.valid);
    console.log(
      this.searchFormControl.datebook,
      this.searchFormControl.maxGuests
    );
    if (!this.searchForm.valid) return false;
    const time = this.valueDate.getTime() / 1000; // as it is an int
    // let time =  // as it is an int
    // let lat = localStorage.getItem('lat')
    // let lng = localStorage.getItem('lng')

    const lat = 39.137384;
    const lng = -104.7073039;
    this.showMessage = false;
    console.log('typeof===>', typeof this.maxGuests);
    const maxGuests = this.maxGuests ? this.maxGuests : 0;
    this.venueSvc.searchVenue(lat, lng, time, maxGuests).subscribe((res) => {
      console.log('searchVenues===>', res['data']);
      this.venues = res['data']['searchVenue'];
      this.showMessage = true;
    });
  }
}
