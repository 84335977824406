import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private dbPath = '/users';
  currentEvent: any;

  public user: any = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('logindata'))['user']
    : { venue: { id: '', venqId: '', name: '' } };

  constructor() {
    // //this.usersRef = db.list(this.dbPath);
    // this.user.venqId="FHRANCH"
    // this.user.venue.id = "ck2jfsswm007a07586hnvizyh"
  }

  getUser(index: number) {
    return this.user[index];
  }

  private handleError(error) {
    console.log(error);
  }
}
