import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  NgForm,
  FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { UserService } from 'app/services/user.service';
import { VenueService } from 'app/services/venue.service';

// import { TimelineaddmodalComponent } from 'src/app/components/timelineaddmodal/timelineaddmodal.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EventCreateComponent implements OnInit {
  file: any;

  eventDetail: any = {
    eventSpace: {
      id: '',
    },
    coordinator: {
      id: '',
    },
  };
  submitted = false;
  venueSpaces: any = [];
  coordinators: any = [];
  isCreate = true;

  constructor(
    public userSvc: UserService,
    public venueSvc: VenueService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getVenueSpaces();
    this.getCoordinators();
    if (this.route.snapshot.params.id) {
      this.isCreate = false;
      this.venueSvc.event(this.route.snapshot.params.id).subscribe((res) => {
        this.eventDetail = res['data']['event'];
      });
    }
  }

  cancel() {
    this.router.navigate(['/events']);
  }
  getVenueSpaces() {
    this.venueSvc.spaces().subscribe((res) => {
      this.venueSpaces = res['data']['venueSpaces'] || [];

      // Now Sort them by name
      if (this.venueSpaces.length > 0) {
        this.venueSpaces = this.venueSpaces.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      }
    });
  }
  getCoordinators() {
    this.venueSvc.users().subscribe((res) => {
      this.coordinators = res['data']['venueUsers'];
    });
  }

  onSubmit(eventForm: NgForm) {
    if (eventForm.valid) {
      const controls = eventForm.controls;
      const payload = {
        coordinator: controls.eventCoordinator.value,
        partyName: controls.partyName.value,
        eventSpace: controls.eventSpace.value,
        eventType: controls.eventType.value,
        guestCount: controls['guestCount'].value || 0,
        startTime: controls.startTime.value,
        description: controls.description.value,

        status: 'active',
      };

      if (controls.onDate.value !== undefined) {
        const date = new Date(controls.onDate.value);
        payload['onDate'] = date.getTime() / 1000; // Because it can't INT this high
      }

      return false;
      this.venueSvc.createEvent(payload).subscribe((res) => {
        this.router.navigate(['events']);
      });
    }
  }

  Update(eventForm: NgForm) {}
  onFileInput(event) {
    if (event.target.files[0]) {
      const reader = new FileReader();
      this.file = event.target.files[0];
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(event.target.files[0]);
    }
  }

  handleReaderLoaded(e) {
    const payload = {
      id: this.route.snapshot.params.id,
      objectType: 'floorPlan',
      objectId: this.route.snapshot.params.id,
      imageEncoded: 'data:image/png;base64,' + btoa(e.target.result),
      imagename: this.file.name.split('.').slice(0, -1).join('.'),
    };
    this.venueSvc
      .uploadImage(this.route.snapshot.params.id, payload)
      .subscribe((res) => {
        this.eventDetail.floorPlan = res['data']['uploadImage']['url'];
        this.updatEvent(res['data']['uploadImage']['url']);
      });
  }

  updatEvent(url: string) {
    const payload = {
      id: this.route.snapshot.params.id,
      venue: this.userSvc.user.venue.id,
      partyName: this.eventDetail.partyName,
      floorPlan: url,
    };
    this.venueSvc
      .updateEvent(this.route.snapshot.params.id, payload)
      .subscribe((res) => {});
  }

  openDialog() {}

  sortData(event) {}
}
