<h2 mat-dialog-title>{{ data.id ? 'Edit' : 'Add' }} Note</h2>
<form #noteForm="ngForm">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>Date</mat-label>

          <input
            type="text"
            matInput
            ngModel
            name="dateValue"
            [(ngModel)]="dateValue"
            class="form-control"
            placeholder="Choose a date"
          />

          <!-- <mat-error *ngIf="timelinetime.invalid">Please Enter Time</mat-error> -->
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-label>Note</mat-label>
        <angular-editor
          [config]="editorConfig"
          name="note"
          [(ngModel)]="data.note"
        ></angular-editor>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>Access</mat-label>
          <mat-select [(ngModel)]="data.access" name="access" #access="ngModel">
            <mat-option value="all">All</mat-option>
            <mat-option value="client">Client Only</mat-option>
            <mat-option value="vendor">Vendor Only</mat-option>
            <mat-option value="client/vendor">Client/Vendor Only</mat-option>
            <mat-option value="venue">Venue Only</mat-option>
          </mat-select>
          <mat-error *ngIf="access.invalid">Please Select Access</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="btn mat-raised-button bg-secondary"
      [mat-dialog-close]="close"
    >
      Cancel
    </button>
    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onAddNote(noteForm, false)"
      *ngIf="!data.id"
      cdkFocusInitial
    >
      Create
    </button>

    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onAddNote(noteForm, true)"
      *ngIf="!data.id"
      cdkFocusInitial
    >
      Next Item
    </button>

    <button
      mat-button
      class="btn mat-raised-button bg-primary"
      (click)="onUpdateNote(noteForm)"
      *ngIf="data.id"
      cdkFocusInitial
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
