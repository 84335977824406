<div class="example-container">
  <form class="text-center border border-light p-5" #eventForm="ngForm">

    <input type="hidden" name="key" >

    <div class="row">
      <div class="col-md-6">
    		<div class="form-group">
    	    <mat-form-field appearance="outline">
						<mat-label>Name</mat-label>
    		    <input 
							matInput 
							name="venueSpaceName"
							[(ngModel)]="venueSpace.name"
							#name="ngModel"
		    		  placeholder="Name" 
					  	required>
					  <mat-error *ngIf="name.invalid">Please Enter Name</mat-error>
    		  </mat-form-field>
    		</div>

    		<div class="form-group">
    		  <mat-form-field appearance="outline">
						<mat-label>Sq Ft</mat-label>
    		    <input 
							matInput 
							type="number"
							placeholder="Sq Ft" 
							name="venueSpacesqft"
							[(ngModel)]="venueSpace.sqft"
							#sqft="ngModel"
					  	required>
					  <mat-error *ngIf="sqft.invalid">Please Enter sqft</mat-error>
    		  </mat-form-field>
    		</div>
      </div>

      <div class="col-md-6">
    		<!-- <div class="form-group" style="margin-top: 50px">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
					placeholder="Floor plan" 
					
				
              required>
    		  </mat-form-field>
    		</div> -->
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>Max Guests</mat-label>
						<input 
							matInput 
							type="number"
							placeholder="Max Guests" 
							name="venueSpacemaxGuests"
							[(ngModel)]="venueSpace.maxGuests"
							#maxGuests="ngModel"
							required>
						<mat-error *ngIf="maxGuests.invalid">Please Enter max Guests</mat-error>
					</mat-form-field>
				</div>
    		<div class="form-group" >
    		  <mat-form-field appearance="outline">
						<mat-label>Type </mat-label>
						<mat-select [(ngModel)]="venueSpace.type" name="venueSpacetype" #type="ngModel" required>
							<mat-option value="indoor">indoor</mat-option>
							<mat-option value="outdoor">outdoor</mat-option>
							<mat-option value="indoor/outdoor">indoor/outdoor</mat-option>
							
						  </mat-select>
    		    <!-- <input
    		    	matInput 
							placeholder="Type" 
							name="venueSpacetype"
							[(ngModel)]="venueSpace.type"
							#type="ngModel"	
					  	required> -->
					  <mat-error *ngIf="type.invalid">Please Select Type</mat-error>
    		  </mat-form-field>
    		</div>
    	</div>
    </div>
    <div class="submit text-center">
      <button type="submit" *ngIf="isCreate" class="btn mat-raised-button bg-primary" (click)="onSubmit(eventForm)">Create</button>
      <button type="submit" *ngIf="!isCreate" class="btn mat-raised-button bg-primary" (click)="Update(eventForm)">Update</button>
    </div>

  </form>
</div>