import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss'],
})
export class EnterOtpComponent implements OnInit {
  showEmail = true;
  email: any = '';
  passcode: any = '';
  userId: any = '';
  constructor(private venueSvc: VenueService, private router: Router) {}

  ngOnInit() {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      const controls = form.controls;

      const payload = {
        userEmail: controls.email.value,
        userType: 'venueUser',
      };
      this.venueSvc.sendOTP(payload).subscribe(
        (res) => {
          this.showEmail = false;
          this.userId = res['data']['sendOTP']['userId'];
          this.venueSvc.showSuccess('Otp Sent..Please check your email');
        }
        // (error) => {
        //   this.venueSvc.showFailure("There was some error sending the Otp");
        // }
      );
    }
  }
  onSubmitPassCode(form: NgForm) {
    if (form.valid) {
      const controls = form.controls;

      const payload = {
        otp: controls.passcode.value,
        userType: 'venueUser',
        id: this.userId,
      };
      this.venueSvc.verifyOTP(payload).subscribe((res) => {
        if (res['data']['verifyOTP']['status'] === 'success') {
          localStorage.setItem('userIdPass', this.userId);
          this.venueSvc.showSuccess(res['data']['verifyOTP']['message']);
          this.router.navigate(['/create-password']);
        } else {
          this.venueSvc.showFailure(res['data']['verifyOTP']['message']);
        }
      });
    }
  }
}
