import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from 'app/components/delete/delete.component';
import { VenueService } from 'app/services/venue.service';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss'],
})
export class SpacesComponent implements OnInit {
  public spaces: any = [];
  searchParam: any = '';
  spaceId: any = '';

  constructor(private venueSvc: VenueService, public dialog: MatDialog) {}

  ngOnInit() {
    console.log('initalized');
    this.getVenueSpaces();
  }

  getVenueSpaces() {
    this.venueSvc.spaces().subscribe((res) => {
      this.spaces = [...res['data']['venueSpaces']].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    });
  }

  searchEventSpace() {
    if (!this.searchParam) {
      this.getVenueSpaces();
    } else {
      this.spaces = this.spaces.filter((x) => {
        // flag = String(x.phoneNo)

        return x.name
          .trim()
          .toLowerCase()
          .includes(this.searchParam.trim().toLowerCase());
      });
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { eventID: this.spaceId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'helsdfsdfsdlo' || result === undefined) {
      } else {
        this.venueSvc.deleteVenueSpace(this.spaceId).subscribe((res) => {
          this.getVenueSpaces();
          this.venueSvc.showSuccess('Event Space Removed Successfully');
        });
      }
    });
    this.getVenueSpaces();
  }
  deleteEventSpace(spaceId) {
    this.spaceId = spaceId;
    this.openDialog();
    this.getVenueSpaces();
  }

  sortData(e) {
    if (e.direction === 'asc') {
      if (e.active === 'name' || e.active === 'type') {
        this.spaces.sort(this.dynamicSort(e.active));
      } else if (e.active === 'sqft' || e.active === 'maxGuests') {
        this.spaces.sort((a, b) => {
          return a[e.active] - b[e.active];
        });
      } else {
        this.spaces.sort(this.dynamicSort(e.active));
      }
    } else {
      if (e.active === 'name' || e.active === 'type') {
        this.spaces.sort(this.dynamicSort('-' + e.active));
      } else if (e.active === 'sqft' || e.active === 'maxGuests') {
        this.spaces.sort((a, b) => {
          return b[e.active] - a[e.active];
        });
      } else {
        this.spaces.sort(this.dynamicSort('-' + e.active));
      }
    }
  }
  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }
}
