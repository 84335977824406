import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-imagepreview',
  templateUrl: './imagepreview.component.html',
  styleUrls: ['./imagepreview.component.scss'],
})
export class ImagepreviewComponent implements OnInit {
  close = 'helsdfsdfsdlo';
  constructor(
    public dialogRef: MatDialogRef<ImagepreviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
