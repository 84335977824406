<div class="example-container">


	  

  <form class="text-center border border-light p-5" #eventForm="ngForm">

	<input type="hidden" name="key">
	<div class="row">
	<div class="col-md-6">
	<div class="form-group">
			<mat-form-field appearance="outline">
					<mat-label>venQID</mat-label>
			  <input class="uppercase"
			    [(ngModel)]="venqID"
				  matInput 
				  name="venQID"
				  placeholder="VenQID" 
          required>
			</mat-form-field>
			<div style="color:red" *ngIf="!found">No Vendor Matched</div>
				</div>
		  </div>
		  <div class="col-md-2">
		  <button type="button" class="btn mat-raised-button mat-primary" (click)="searchVendor()">Search Vendor</button>
		  </div>
		</div>
		  

    <div class="row">
      <div class="col-md-6">
    		<div class="form-group">
    	    <mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
    		    <input 
    		    	matInput 
    					placeholder="Name..." 
    					disabled="true"
    					name="vendor"
    					[(ngModel)]="vendorDetail.name"
              required>
    		  </mat-form-field>
    		</div>

    		<div class="form-group">
    		  <mat-form-field appearance="outline">
							<mat-label>Category</mat-label>
    		    <input 
    					matInput 
    					disabled="true"
    					placeholder="Category" 
    					name="category"
    					[(ngModel)]="vendorDetail.category"
              required>
    		  </mat-form-field>
    		</div>

    		<!-- <div class="form-group">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
					placeholder="Availability" 
					name="availability"
              required>
    		  </mat-form-field>
    		</div> -->
			</div>
			<div class="col-md-6">
					<div class="form-group">
							<mat-form-field appearance="outline">
									<mat-label>Address</mat-label>
								<input 
    							matInput 
    							disabled="true"
    							placeholder="Address" 
    							name="address"
    							[(ngModel)]="vendorDetail.address"
									required>
							</mat-form-field>
						</div>
			</div>

      <!-- <div class="col-md-4">
    		<div class="form-group">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
					placeholder="Phone Number" 
					name="phoneNo"
              required>
    		  </mat-form-field>
    		</div>


    		<div class="form-group">
    		  <mat-form-field>
    		    <input
    		    	matInput placeholder="Price Range" 
					name="priceRange"
              required>
    		  </mat-form-field>
    		</div>
    	</div> -->

      <!-- <div class="col-md-4">
        <div class="mx-auto img" style="width: 160px; height: 160px; margin: 15px; border: 1px solid">
          <div *ngIf="image">
            <img [src]="image" alt="Profile Photo">
          </div>
        </div>
        <input type="file" hidden #upload accept=".png,.jpg"/>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-6">
              <button class="btn btn-block mat-raised-button">Choose</button>
            </div>
            <div class="col-6">
              <button class="btn btn-block mat-raised-button mat-primary">Upload</button>
            </div> 
          </div>
        </div>
      </div> -->
    </div>

    <div class="submit">
      <button type="submit" [disabled]="!vendorId" class="btn mat-raised-button bg-primary" (click)="onSubmit(eventForm)">Add Vendor</button>
    </div>

  </form>
</div>