import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common';
import { VenueService } from 'app/services/venue.service';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'app-timelineaddmodal',
  templateUrl: './timelineaddmodal.component.html',
  styleUrls: ['./timelineaddmodal.component.scss'],
})
export class TimelineaddmodalComponent implements OnInit {
  close = false;
  eventId: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '0',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'redo',
        'italic',
        'justifyFull',
        ,
        'heading',
        'indent',
      ],
      ['fontSize', 'customClasses', 'insertVideo'],
    ],
  };
  timeError = false;
  timelinetime: any;
  constructor(
    public dialogRef: MatDialogRef<TimelineaddmodalComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    public venueSvc: VenueService,
    public eventSvc: EventsService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
   if(this.data.id){
     
     const sDate = new Date(this.data.onTime)
     const oDate = new Date( sDate.getTime()  - (sDate.getTimezoneOffset() * 1000 *60) )
     const pDate = oDate.toISOString().split(".")
     this.data.onTime = pDate[0];
     console.log("EDIT", this.data.onTime , oDate.toISOString() , sDate.getTimezoneOffset() )
   }
  }
  convertDate(strDate: string) {
    const i = parseInt(strDate, 10);

    this.data.onTime = new Date(i * 1000);
  }

  checkFormat(time) {
    const format =
      /((1[0-2]|0?[1-9]):([0-5][0-9]) ?(AM|am|aM|Am|PM|pm|pM|Pm))/.test(time);
    return true; // format.test(time)
  }

  onClosed(formControl) {
    formControl.control.markAsTouched();
  }

  onSubmitTimeLine(timeLineForm: NgForm, next: boolean = false) {
    console.log(this.data.onTime, "ONTIME")
    if (timeLineForm.valid) {
      const isNext = next;

      if (this.checkFormat(this.data.onTime)) {
      
        let datetime: any = timeLineForm.value.onTime;
        datetime = new Date(datetime).toISOString();
        this.data.onTime = new Date();
        console.log('savetime', datetime , timeLineForm.value)

        const obj = {
          item: this.data.item,
          // onTime: datetime,
          onTime: datetime,
          description: this.data.description,
          access: this.data.access,
          event: this.data.event,
          where: this.data.where,
        };
        this.timeError = false;
        this.eventSvc.createTimeLineItem(obj).subscribe((res) => {
          this.venueSvc.showSuccess('Timeline Item Added Successfully');
          this.dialogRef.close(isNext);
        });
      } else {
        this.timeError = true;
      }
    }
  }
  doNothing() {}

  onupdateTimeLine(timeLineForm: NgForm) {
    if (timeLineForm.valid) {
      delete this.data['__typename'];

      if (this.checkFormat(this.data.onTime)) {
        let datetime: any = timeLineForm.value.onTime;
        datetime = new Date(datetime).toISOString();

        const obj = {
          id: this.data.id,
          item: this.data.item,
          onTime:datetime,
          description: this.data.description,
          access: this.data.access,
          event: this.data.event,
          where: this.data.where,
        };
        this.timeError = false;
        this.eventSvc.updateTimeLineItem(obj).subscribe((res) => {
          this.venueSvc.showSuccess('Timeline Item Updated Successfully');
          this.dialogRef.close();
        });
      } else {
        console.log('yes');
        this.timeError = true;
      }
    }
  }
}
