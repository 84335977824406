import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  route: boolean;
  isAdmin = false;
  constructor(
    private router: Router,
    public authService: AuthService,
    public userSvc: UserService
  ) {
    this.router.events.subscribe((e) => {
      // tslint:disable-next-line: curly
      if (e instanceof NavigationEnd) {
        this.route = e.url === '/sign-in' ? false : true;
      }
    });
    const role = this.userSvc.user.role;
    this.isAdmin = role === 'admin' ? true : false;
  }
  title = 'venue-console';
  checkKeep() {
    const keep = localStorage.getItem('keep');
    // if (keep) {
    //   if (keep === '1') {
    //   } else {
    //     // localStorage.removeItem('logindata')
    //     // localStorage.removeItem('token')
    //   }
    // } else {
    //   //   localStorage.removeItem('logindata')
    //   //  localStorage.removeItem('token')
    // }
  }
}
