import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isAdmin = false;
  constructor(
    public authService: AuthService,
    public userSvc: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('initalized');
    const role = this.userSvc.user.role;
    this.isAdmin = role === 'admin' ? true : false;
  }
  goToLink(url: string) {
    window.open(url, '_blank');
  }

  logout() {
    localStorage.removeItem('logindata');
    localStorage.removeItem('token');
    this.authService.isLoggedin = false;
    this.router.navigate(['sign-in']);
  }
}
