<div class="card">
    <div class="logo">
      <img src="assets/logo 2.png">
    </div>
      <div class="card1">
        <div class="col-md-6 center" *ngIf="showEmail">
          <h2>Create new Password</h2>
          <!-- <p>Deliver Their Dream Wedding</p> -->
          <form class="text-center " #eventForm="ngForm" >
            <input type="hidden" name="key" >
            <div class="row">
              <div class="col-md-12">
                <div class="form-group pt-2">
                  <mat-form-field appearance="fill">
                    <input 
                      matInput 
                      name="password"
                      [(ngModel)]="password"
                        type="password"
                        #pass="ngModel"
                      placeholder="Password" 
                      required>
                      <mat-error *ngIf="pass.invalid">Please Enter Password</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="form-group pt-2">
                        <mat-form-field appearance="fill">
                          <input 
                            matInput 
                            name="confirmpassword"
                            [(ngModel)]="confirmpassword"
                            type="password"
                            #confirmpass="ngModel"
                            placeholder="Confirm Password" 
                            required>
                          <mat-error *ngIf="confirmpass.invalid">Please Enter Confirm Password</mat-error>
                            </mat-form-field>
                          </div>
               
                </div>
              </div>
            <!-- <mat-checkbox (click)="check()" [(checked)]="!IsChecked">Keep me logged in</mat-checkbox> -->
            <div class="pt-1 submit text-left">
              <button type="submit" (click)="onSubmit(eventForm)" class="btn mat-raised-button mat-primary" >Change Password</button>
            </div>
            
          </form>
        </div>
        
  </div>
</div>
