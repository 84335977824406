<div class="example-container">
  <form class="text-center border border-light p-5" #eventForm="ngForm">

    <input type="hidden" name="key">

    <div class="row">
      <div class="col-md-4">
    		<div class="form-group">
    	    <mat-form-field>
    		    <input 
    		    	matInput 
    		    	placeholder="Name" 
              required>
    		  </mat-form-field>
    		</div>

    		<div class="form-group">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
    		    	placeholder="Contact Person" 
              required>
    		  </mat-form-field>
    		</div>

    		<div class="form-group">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
    		    	placeholder="Phone Number" 
              required>
    		  </mat-form-field>
    		</div>
      </div>

      <div class="col-md-4">
    		<!-- <div class="form-group">
    		  <mat-form-field>
    		    <input 
    		    	matInput 
    		    	placeholder="Username" 
              required>
    		  </mat-form-field>
    		</div> -->

    		<!-- <div class="form-group">
    		  <mat-form-field>
				    <input 
				    	matInput 
				    	placeholder="Reset Password" 
				    	[type]="hide ? 'text' : 'password'">
				    <button 
				    	mat-icon-button 
				    	matSuffix 
				    	(click)="hide = !hide" 
				    	[attr.aria-label]="'Hide password'" 
				    	[attr.aria-pressed]="hide">
				    	<mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
				    </button>
				  </mat-form-field>
			</div> -->
			<div class="form-group">
				<mat-form-field>
				  <input 
					  matInput 
					  placeholder="Email" 
				required>
				</mat-form-field>
			  </div>

			  <div class="form-group">
				<mat-form-field>
				  <input 
					  matInput 
					  placeholder="Alt Email" 
				required>
				</mat-form-field>
			  </div>
			  <div class="form-group">
				<mat-form-field>
				  <input 
					  matInput 
					  placeholder="Alt Phone" 
				required>
				</mat-form-field>
			  </div>
    	</div>

      <div class="col-md-4">
       
    		
    	</div>
    </div>

    <div class="submit">
      <button type="submit" class="btn mat-raised-button mat-primary" (click)="onSubmit(eventForm)">Submit</button>
    </div>

  </form>
</div>