// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphQL: {
    serverUri: "https://graphql.venqapp.com/",
   // serverUri: 'http://localhost:4003/',
  },
  firebase: {
    apiKey: 'AIzaSyBgu8757raGnhbDDZPboUvPZ3sCbOFSXnk',
    authDomain: 'venue-78912.firebaseapp.com',
    databaseURL: 'https://venue-78912.firebaseio.com',
    projectId: 'venue-78912',
    storageBucket: 'venue-78912.appspot.com',
    messagingSenderId: '1024316280633',
    appId: '1:1024316280633:web:a3d79e14eaf0f17e4522e8',
    measurementId: 'G-B21TNGRLRL',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
