<div class="example-container">
  <form class="text-center border border-light p-5" #eventForm="ngForm">
    <!-- <input type="hidden" name="key" [(ngModel)]="eventService.selectedEvent.key" > -->
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="Party Name"
              name="partyName"
              [(ngModel)]="eventDetail.partyName"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              type="text"
              required
              name="onDate"
              matInput
              [(ngModel)]="eventDetail.onDate"
              placeholder="Choose a date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- <input type="text" matInput placeholder="Event Date" name="onDate" [(ngModel)]="eventDetail.onDate"
              required> -->
          </mat-form-field>
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="Start Time"
              name="startTime"
              [(ngModel)]="eventDetail.startTime"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Coordinator</mat-label>
            <mat-select
              [(ngModel)]="eventDetail.coordinator.id"
              name="eventCoordinator"
              #coordinator="ngModel"
              required
            >
              <mat-option
                *ngFor="let coordinator of coordinators"
                [value]="coordinator.id"
                >{{ coordinator.username }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="coordinator.invalid"
              >Please Select coordinator</mat-error
            >
          </mat-form-field>

          <!-- <mat-form-field class="example-full-width">
					
					</mat-form-field> -->
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Event Space</mat-label>

            <mat-select
              [(ngModel)]="eventDetail.eventSpace.id"
              name="eventSpace"
              #eventSpace="ngModel"
              required
            >
              <mat-option
                *ngFor="let space of venueSpaces"
                [value]="space.id"
                >{{ space.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="eventSpace.invalid"
              >Please Select Event Space</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Event Type</mat-label>
            <mat-select
              [(ngModel)]="eventDetail.eventType"
              name="eventType"
              #type="ngModel"
              required
            >
              <mat-option value="Wedding">Wedding</mat-option>
              <mat-option value="Anniversary">Anniversary</mat-option>
              <mat-option value="Birthday">Birthday</mat-option>
              <mat-option value="Corporate">Corporate</mat-option>
            </mat-select>
            <mat-error *ngIf="type.invalid">Please Select Event Type</mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              type="number"
              matInput
              placeholder="Guest Count"
              [(ngModel)]="eventDetail.guestCount"
              name="guestCount"
            />
          </mat-form-field>
          <mat-form-field>
            <textarea
              type="text"
              matInput
              placeholder="Description"
              name="description"
              [(ngModel)]="eventDetail.description"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z8">
      <mat-tab-group *ngIf="!isCreate" mat-align-tabs="start">
        <mat-tab label="TimeLine">
          <i (click)="openDialog()" class="large material-icons">add_circle</i>

          <table id="text-center" matSort (matSortChange)="sortData($event)">
            <thead>
              <tr class="vq-header">
                <th mat-sort-header="name">Item</th>
                <th mat-sort-header="calories">On Time</th>
                <th mat-sort-header="fat">Description</th>
                <th mat-sort-header="carbs">Access</th>
              </tr>
            </thead>

            <tbody>
              <tr class="vq-rows" *ngFor="let timeline of eventDetail.timeline">
                <td>
                  <a [routerLink]="['/timeline/' + timeline.id]">
                    {{ timeline.item }}</a
                  >
                </td>
                <td>{{ timeline.onTime }}</td>
                <td>{{ timeline.description }}</td>
                <td>{{ timeline.access }}</td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
        <!-- <mat-tab label="VEn">Content 2</mat-tab> -->
        <mat-tab label="Floor Plan">
          <div class="square">
            <img [src]="eventDetail.floorPlan" class="img-fluid" alt="" />
          </div>
          <button class="mb" (click)="fileInput.click()">
            <i class="large material-icons">library_add</i>
            <span>Select</span>
            <input
              #fileInput
              type="file"
              (change)="onFileInput($event)"
              style="display: none"
            />
          </button>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div>
      <button
        mat-button
        color="accent"
        class="btn mat-raised-button"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        type="submit"
        *ngIf="isCreate"
        class="btn mat-raised-button mat-primary"
        (click)="onSubmit(eventForm)"
      >
        Create
      </button>
      <button
        type="submit"
        *ngIf="!isCreate"
        class="btn mat-raised-button mat-primary"
        (click)="Update(eventForm)"
      >
        Update
      </button>
    </div>
  </form>
</div>
