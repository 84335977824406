<div class="dptContainer">
    <div class="dptRow dptTimeSelector">
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Hour</mat-label>
                <mat-select>
                  <mat-option *ngFor="let hour of hours" [value]="hour">
                    {{hour}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Hour</mat-label>
                <mat-select>
                  <mat-option *ngFor="let hour of mins" [value]="hour">
                    {{hour}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Hour</mat-label>
                <mat-select>
                  <mat-option *ngFor="let hour of ampm" [value]="hour">
                    {{hour}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    
</div>
